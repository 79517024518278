<template>
  <div class="nav-box" :style="{ 'background-color': navColor }">
    <div class="nav">
      <div class="left">
        <img src="../assets/newImg/logo.png" alt="">
      </div>
      <div class="right">
        <template v-if="navlistShow">
          <div class="pc-nav" @click="changeNav(item)"
            :class="{ 'right-item': true, 'active': currentPath === item.src }" v-for="(item) in navList"
            :key="item.id">
            {{ item.title }}</div>
        </template>
        <div class="mobile-nav">
          <van-icon name="apps-o" size="30px" @click="menuBtn" />
        </div>
        <el-dropdown class="new">
          <span class="el-dropdown-link">
            用户中心<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>{{ $store.state.userInfo.mobile ? $store.state.userInfo.name : ''
              }}</el-dropdown-item>
            <el-dropdown-item v-if="!$store.state.userInfo.mobile" @click.native="openDialog(1)">登录</el-dropdown-item>
            <el-dropdown-item @click.native="qiutConfirm">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!-- <div class="new" style="margin-right: 20px;cursor: pointer;" @click="openDialog(1)">登录</div>

        <div class="new" @click="qiutConfirm" style="margin-right: 20px;cursor: pointer;">退出</div>
        <div>用户：{{ $store.state.userInfo.mobile ? $store.state.userInfo.mobile:'未登录' }}</div> -->

        <!-- <div class="new" style="margin-right: 20px;cursor: pointer;" @click="openDialog(0)">注册</div> -->
        <!-- <div class="right-item">关于掌动</div>
      <div class="right-item">旗下产品</div>
      <div class="right-item">商务合作</div>
      <div class="right-item">联系我们</div> -->
      </div>
      <!-- 移动端导航菜单 -->
      <van-popup @close="popupClose" v-model="$store.state.navpopupShow" position="right" round
        :style="{ height: '26%' }">
        <div class="navMenu-box">
          <div class="navMenu-item" v-for="(item, index) in navTextList" :key="index" @click="NavClick(item.src)">{{
            item.text }}</div>
          <!-- <div class="navMenu-item">加入我们</div>
        <div class="navMenu-item">联系我们</div> -->
        </div>
      </van-popup>
    </div>
    <el-dialog :title="flag ? '登录' : '注册'" :visible="centerDialogVisible" width="34%" center
      @close="centerDialogVisible = false" :modal="false">
      <el-form :model="form" :rules="rules" ref="formRef">
        <el-form-item prop="mobile">
          <el-input placeholder="请输入您的手机号" v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" show-password placeholder="请输入您的密码" v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item v-if="!flag" prop="requirePassword">
          <el-input show-password placeholder="请您确认密码" v-model="form.requirePassword"></el-input>
        </el-form-item>
        <el-form-item v-if="!flag" prop="name">
          <el-input placeholder="请输入真实姓名" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item v-if="!flag" prop="code">
          <el-input placeholder="请输入身份证号码" v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item prop="checked">
          <el-checkbox style="margin-bottom: 0;" v-model="form.checked">我已阅读同意
          </el-checkbox>
          <span style="color: #3eb74f;" @click="agreementFn(1)">《掌动科技用户协议》</span>
          <span style="color: #3eb74f;" @click="agreementFn(2)">《掌动科技用户隐私政策》</span>
        </el-form-item>
        <el-form-item>
          <div style="width: 100%; text-align: center;">
            <el-button style="width: 100%;" round type="primary" @click="submit">{{ flag ? '登录' :
              '注册'
              }}</el-button>
          </div>

        </el-form-item>
        <el-form-item>
          <div style="width: 100%;text-align: center;cursor: pointer;">
            {{ flag ? '暂无账号，去申请' : '已有账号，去' }} <span style="color: #5fadff;" @click="gotoRegister">{{ flag ? '注册' :
              '登录' }}</span>?
          </div>
        </el-form-item>
      </el-form>

    </el-dialog>

    <!-- 注册提示 -->
    <el-dialog title="温馨提示" :visible.sync="registerVisible" width="50%" center :modal="false">
      <div class="registerVisible">
        感谢您使用掌动科技的服务！<br>
        在您使用掌动科技服务时，我们将需要收集您的手机号码、邮箱、身份证号码，以用于为您提供电魂注册、登录和依照法规进行实名认证。收集您的IP地址、个人常用设备信息、充值记录信息、游戏角色信息等，以用于为您提供游戏服务。当您使用某些特定服务时，您有可能还需向我们提供其他相关信息，在收集前，我们会另有提示~
        <div class="textBox">
          <div>点击“同意”，即表示您已阅读并充分理解：</div>
          <div><span style="color: #3eb74f;" @click="agreementFn(1)">《掌动科技用户协议》</span> <span
              style="color: #ff0000;">用户协议内容有更新，请及时查看！</span>
          </div>
          <div><span style="color: #3eb74f;" @click="agreementFn(2)">《掌动科技用户隐私政策》</span> <span
              style="color: #ff0000;">隐私政策内容有更新，请及时查看！</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="registerVisible = false">取 消</el-button>
        <el-button type="primary" @click="registerVisibleSubmit">同意</el-button>
      </span>
    </el-dialog>

    <!-- 用户协议 -->
    <el-dialog :title="currentAgree == 1 ? '掌动科技用户服务协议' :'掌动科技游戏用户隐私政策'" :visible.sync="agreementVisible" width="60%"
      center :modal="false">
      <el-scrollbar style="height:400px">
        协议须知：<br>
        <!-- {{ agreementText }}  -->
        <agreementText v-if="currentAgree == 1"></agreementText>
        <policyText v-if="currentAgree==2"></policyText>
      </el-scrollbar>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="agreementSubmit">已读</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import agreementText from "./agreement.vue";
import policyText from "./policy.vue";
import {  mapState} from "vuex";
import { login, registerApi } from "../api/index";
export default {
  props: {
    navList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const passValidator = (rule, value, callback) => {
      if (value === this.form.password) {
        callback()
      } else {
        callback(new Error('两次输入密码不一致!'));
      }
    }
    const checkedValidator = (rule, value, callback) => {
      if (true === this.form.checked) {
        callback()
      } else {
        callback(new Error('请勾选用户协议'));
      }
    }
    return {
      form: {
        mobile: '',
        password: '',
        requirePassword: '',
        name:'',
        code:'',
        checked:false
      },
      formRef: null,
      flag: true,
      currentAgree:1,
      centerDialogVisible: false,
      registerVisible:false,
      agreementVisible:false,
      currentPath: '',
      windowWidth: document.documentElement.clientWidth,
      navlistShow: true,
      navColor: '#fff',
      timer: null,
      navTextList: [
        {
          text: '首页',
          src: '/home'

        },
        // {
        //   text: '旗下游戏',
        //   src: '/game'

        // },
        {
          text: '加入我们',
          src: '/join'

        },
        {
          text: '联系我们',
          src: '/contact'
        }
      ],
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入正确手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          // { pattern: /^\d{6}$/, message: '密码为6位数字', trigger: 'blur' }
        ],
        requirePassword: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: passValidator, trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          // { validator: passValidator, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' },
          { pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确身份证号', trigger: 'blur' }
          
          // { validator: passValidator, trigger: 'blur' }
        ],
        checked: [
          // { required: true, message: '请勾选用户协议', trigger: 'blur' },
          { validator: checkedValidator, trigger: 'blur' }
        ]
      },
      // navpopupShow: false
    }
  },
  computed: mapState([
    'userInfo',
  ]),
  components:{
    agreementText,
    policyText
  },
  methods: {
    agreementFn(value){
      this.currentAgree=value
      this.agreementVisible = true
    },
    agreementSubmit(){
      this.agreementVisible=false
      // this.registerVisible = false
      // this.flag = !this.flag
    },
    registerVisibleSubmit(){
      this.registerVisible = false
      this.flag = !this.flag
    },
    gotoRegister() {
      console.log(this.flag);
      this.$nextTick(() => {
        this.$refs.formRef.clearValidate()
        this.form = {
          mobile: '',
          password: '',
          requirePassword: '',
          name: '',
          code: '',
          checked: false
        }
      })
      if(!this.flag){
        this.flag = !this.flag
        return
      }
      this.registerVisible=true
      
    },
    openDialog(value) {
      console.log(111);
      
      if (this.$store.state.userInfo.mobile) return this.$message('您已登陆')
      this.centerDialogVisible = true
      this.$nextTick(() => {
        this.$refs.formRef.clearValidate()
        this.form = {
          mobile: '',
          password: '',
          requirePassword: '',
          name: '',
          code: '',
          checked: false
        }
      })
      if (value) {
      
        this.flag = true
      } else {
        this.flag = false
      }
    },
    async submit() {
      await this.$refs.formRef.validate()
      console.log('form', this.form);
      console.log(this.flag);
      if (this.flag){
        const res = await login({
          "mobile": this.form.mobile,
          "password": this.form.password
        })
        console.log(res);

        if(res.data.code!==200){
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
          return 
        }
       
        this.$store.commit('setUserInfo', res.data.data)
        this.centerDialogVisible = false
        this.$message({
          message: '登录成功',
          type: 'success'
        });
      }else{
        console.log('form', this.form);
        const res = await registerApi({
          "mobile": this.form.mobile,
          "password": this.form.password,
          "name": this.form.name,
          "idcard": this.form.code
        })
        if (res.data.code !== 200) {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
          return
        }
        console.log(res);
        
        this.flag = !this.flag
        this.$message({
          message: '注册成功',
          type: 'success'
        });
        this.$nextTick(() => {
          this.$refs.formRef.clearValidate()
          this.form = {
            mobile: '',
            password: '',
            requirePassword: '',
            name: '',
            code: '',
            checked: false
          }
        })
      }
      
    },
   
    qiutConfirm(){
      if(!this.$store.state.userInfo.mobile)return this.$message('暂未登录')
      this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.commit('setQuit')
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    NavClick(src) {
      // this.navlistShow = false
      this.$store.commit('setNavpopupShow', false)
      this.$router.push(src)
    },
    changeNav(item) {
      this.$router.push(item.src)

      // console.log(index)
    },
    // 滚动页面时触发导航变色
    handleScroll() {
      // const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log('scrollTop', scrollTop)
      // // 设置背景颜色的透明度
      // if (scrollTop) {
      //   this.navColor = '#ffffff'
      // } else if (scrollTop === 0) {
      //   this.navColor = 'transparent' // 设置回到顶部时，背景颜色为透明
      // }
    },
    // 滚动之前重置
    beforeDestroy() {
      // window.removeEventListener('scroll', this.handleScroll)
    },
    menuBtn() {
      if (this.timer !== null) {
        clearTimeout(this.timer)
      }
      this.$store.commit('setNavpopupShow', true)
      this.$store.commit('setIndex', -1)
    },
    popupClose() {
      if (this.timer !== null) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.$store.commit('setIndex', 0)
      }, 2000)
    }

  },
  mounted() {
    // window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  watch: {
    windowWidth(newVal, oldVal) {
      console.log('new', 'old')
    },
    // 监听路由变化
    '$route.path': function (newVal, oldVal) {
      // console.log(`new_path = ${newVal}, old_path = ${oldVal}`)
      this.currentPath = newVal
    }

  },
  created() {
    console.log('document.documentElement.clientWidth', document.documentElement.clientWidth)
    console.log(this.$route.fullPath)
  }
}
</script>

<style lang="scss" scoped>
    .el-dropdown-link {
      cursor: pointer;
      // color: #409EFF;
    }
  
    .el-icon-arrow-down {
      font-size: 12px;
    }

// 移动端
@media (max-width: 992px) {

 
  .navMenu-box {
    text-align: center;
    padding: 0 20px;
    font-size: 15px;

    .navMenu-item {
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid rgba(135, 135, 135, 0.2);
    }

    // display: flex;
    // flex-direction: column;
    // justify-content: center;
  }

  .nav-box {
    // width: 100%;
    // display: flex;
    // justify-content: center;
    position: fixed;
    z-index: 999999;
    width: 100% !important;

    .nav {

      display: flex;
      padding: 0 20px;
      height: 51px;
      width: 100% !important;
      justify-content: space-between;

      .left {
        width: 100px;
        height: 50px;
        line-height: 50px;

        // background-color: aqua;
        img {
          // height:100%;
          width: 100%;
        }
      }
    }
  }

  .right {
    line-height: 65px;

    .new {
      display: none;
    }
  }

  .pc-nav {
    display: none;
  }

  .mobile-nav {
    display: block;
  }
}

// pc端
@media (min-width: 992px) {


  .registerVisible{
    .textBox{
      margin-top: 10px;
      margin-left: 50px;
      div:nth-child(2){
        margin: 10px 0;
      }
    }
  }
  .pc-nav {
    display: block;
  }

  .mobile-nav {
    display: none;
  }

  .nav-box {
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 999;

    .nav {
      display: flex;

      padding: 0 100px;
      justify-content: space-between;
      height: 50px;
      // width: 100%;
      width: 1500px;
      // background-color: red !important;

      .left {
        // display: flex;
        // align-items: center;
        width: 150px;
        line-height: 45px;

        // background-color: aqua;
        img {
          // height:100%;
          width: 100%;
        }
      }

      .right {
        font-size: 16px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      }

      .right-item {
        position: relative;
        padding: 15px;
        cursor: pointer;
        margin-right: 60px;

        &:hover {
          color: #ff8714;
        }

        &.active {
          color: #ff8714;

          &::before {
            content: ' ';
            display: flex;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 3px;
            width: 40%;
            height: 4px;
            background-color: #ff8714;
          }
        }
      }
    }
  }
}
</style>
