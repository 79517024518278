<template>
  <div class="box">
    <div class="carousel-box">
      <div class="carousel">
        <el-carousel height="150px" autoplay class="text-box-parent" :append-to-body="true"
          :style="{ 'z-index': $store.state.index }">
          <el-carousel-item v-for="item in carouselImgList" :key="item.id">
            <div class="text-box" v-if="item.id === 1">
              <div class="one">掌动游戏</div>
              <div class="two">打造基于游戏的全产业链生态</div>
              <!-- <div class="three">业务模式</div> -->
            </div>
            <img style="width: 100%;height: 100%;" :src="windowWidth > 992 ? item.imgSrc : item.mobileImg" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>

    </div>

    <div class="about">
      <div class="box">
        <div class="one">
          <div class="icon-title">
            <img src="../assets/titleIcon.png" alt="" />
          </div>
          <div class="title">关于掌动</div>
          <div class="icon-title">
            <img src="../assets/titleIcon.png" alt="" />
          </div>
        </div>
        <div class="two">ABOUT&nbsp;&nbsp;PALM</div>
        <div class="three">掌动致力于创造开放卓越的游戏生态</div>
        <div class="four">
          <div class="four-item">掌动科技成立于2013年6月，是一家致力于集自主研发、运营于一体的游戏全产业链的高科技互联网公司。在游戏产</div>
          <div class="four-item">业中具有顶尖的技术和丰富的资源，对游戏的各方面有深刻的认识和优势壁垒。公司秉承着“掌动科技，快乐生活”的理</div>
          <div class="four-item">念，致力于游戏生态全产业链打造，为中国游戏创造开放卓越的游戏生态。</div>
        </div>
        <div class="mobile-four">
          <div class="four-item">
            掌动科技成立于2013年6月，是一家致力于集自主研发、运营于一体的游戏全产业链的高科技互联网公司。
            在游戏产业中具有顶尖的技术和丰富的资源，对游戏各方面有深刻的认识和优势壁垒。
            <!-- 公司秉承着“掌动科技，快乐生活”的理念，致力于游戏生态全产业链打造，为中国游戏创造开放卓越的游戏生态。 -->
          </div>
        </div>
        <div class="five">
          <div class="five-item">
            <div class="five-item-title">1.2亿</div>
            <div>掌动注册用户</div>
          </div>
          <div class="five-item">
            <div class="five-item-title">100+</div>
            <div>掌动开发游戏IP数</div>
          </div>
          <div class="five-item">
            <div class="five-item-title">Top10</div>
            <div>杭州重点文化企业</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="product">
      <div class="box">
        <div class="one">
          <div class="icon-title">
            <img src="../assets/titleIcon.png" alt="" />
          </div>
          <div class="title">产品介绍</div>
          <div class="icon-title">
            <img src="../assets/titleIcon.png" alt="" />
          </div>
        </div>
        <div class="two">OUR&nbsp;&nbsp;PRODUCT</div>
        <div class="content">
          <!-- <div :class="{ 'content-top': true, 'active': BottomCurrentIndex === index }" v-for="(item, index) in lunbo"
            :key="index">
            <div class="left">
              <img :src="item.imgSrc" alt="">
            </div>
            <div class="right">
              1
            </div>
          </div> -->
          <!-- <div class="content-top">
            <div class="left">
              <img :src="lunbo[0].imgSrc" alt="">
            </div>
            <div class="right">
              1
            </div>
          </div> -->
          <div :class="{
            'content-top': true,
            active: BottomCurrentIndex === index,
          }" v-for="(item, index) in lunbo" :key="index">
            <div class="left">
              <img :src="item.imgSrc" alt="" />
            </div>
            <div class="right">
              <div class="right-text-box">
                <div class="right-title">{{ item.title }}</div>
                <div class="line-box">
                  <div class="line1"></div>
                  <div class="line2"></div>
                </div>
                <div class="right-content">{{ item.content }}</div>
                <div class="more" @click="downFn(item)"><i class="el-icon-download"></i><span>下载</span></div>

                <!-- <div class="more" @click="moreBtn"><i class="el-icon-edit"></i><span>更多</span></div> -->
              </div>
            </div>
          </div>

          <div class="content-bottom">
            <div @click="bottomBtn(index)" v-for="(item, index) in lunbo" :key="index" :class="{
              'content-bottom-item': true,
              active: BottomCurrentIndex === index,
            }">
              <img :src="item.iconImg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 游戏视频 -->
    <!-- <div class="video">
      <div class="box">
        <div class="one">
          <div class="icon-title">
            <img src="../assets/titleIcon.png" alt="" />
          </div>
          <div class="title">旗下产品</div>
          <div class="icon-title">
            <img src="../assets/titleIcon.png" alt="" />
          </div>
        </div>
        <div class="two">GAME&nbsp;&nbsp;VIDEO</div>
        <div class="content">
          <div class="left">
            <video style="width: 100%; height: 100%; object-fit: fill" class="videoOne" :muted="mutedOneShow" :src="windowWidth>992?pcvideo.url1:mobilevideo.url1" :controls="mutedOneShow" :loop="false" :autoplay="mutedOneShow" ref="videoOne" :poster="windowWidth>992?videoImg[0].pcImg:videoImg[0].mobileImg"></video>
            <div class="left-bottom-shadow">
              <div class="title">《别吃鸡》第五季冬季乐园预告片</div>
            </div>
            <div class="left-playIcon" @click="leftPlayIconBtn">
              <img v-if="!mutedOneShow" src="../assets/playIcon.png" alt="" />
            </div>
          </div>
          <div class="right">
            <div class="right-top">
              <video style="height: 100%; width: 100%; object-fit: fill" class="videoTwo" :muted="mutedTwoShow" :src="windowWidth>992?pcvideo.url2:mobilevideo.url2" :controls="mutedTwoShow" :loop="false" :autoplay="mutedTwoShow" ref="videoTwo" :poster="windowWidth>992?videoImg[1].pcImg:videoImg[1].mobileImg"></video>
              <div class="left-bottom-shadow">
                <div class="title">《开心点点消》第五季冬季乐园预告片</div>
              </div>
              <div class="left-playIcon" @click="topPlayIconBtn">
                <img v-if="!mutedTwoShow" src="../assets/playIcon.png" alt="" />
              </div>
            </div>
            <div class="right-bottom">
              <video style="height: 100%; width: 100%; object-fit: fill" class="videoThree" :muted="mutedThreeShow" :src="windowWidth>992?pcvideo.url3:mobilevideo.url3" :controls="mutedThreeShow" :loop="false" :autoplay="mutedThreeShow" ref="videoThree" :poster="windowWidth>992?videoImg[2].pcImg:videoImg[2].mobileImg"></video>
              <div class="left-bottom-shadow">
                <div class="title">《奔跑吧原始人》第五季冬季乐园预告片</div>
              </div>
              <div class="left-playIcon" @click="bottomPlayIconBtn">
                <img v-if="!mutedThreeShow" src="../assets/playIcon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 商务合作 -->
    <div class="cooperation">
      <div class="box">
        <div class="textOne">CONTACT</div>
        <div class="textTwo">商务合作</div>
        <div class="btn">
          <div class="btn-box" @click="contactBtn">
            <div>联系我们</div>
            <div class="phone-icon">
              <img src="../assets/phone.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog  @close="close" :modal="false" center title="请扫码下载" :visible="dialogVisible" width="20%">
      <div class="imgbox">
        <div class="image">
          <img :src="codeImg[BottomCurrentIndex].img" alt="">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      videoImg: [
        {
          pcImg: require('@/assets/newImg/别吃鸡/960x500.png'),
          mobileImg: require('@/assets/mobile/mobileVideo1.png')
        },
        {
          pcImg: require('@/assets/newImg/开心点点消.png'),
          mobileImg: require('@/assets/mobile/mobileVideo2.png')
        },
        {
          pcImg: require('@/assets/newImg/奔跑吧原始人.jpg'),
          mobileImg: require('@/assets/mobile/mobileVideo3.png')
        }
      ],

      pcvideo: {
        url1: require('@/assets/video/别吃鸡.mp4'),
        url2: require('@/assets/video/点点消.mp4'),
        url3: require('@/assets/video/奔跑吧原始人.mp4')
      },
      mobilevideo: {
        url1: require('@/assets/mobileVideo/别吃鸡.mp4'),
        url2: require('@/assets/mobileVideo/开心点点消.mp4'),
        url3: require('@/assets/mobileVideo/奔跑吧原始人.mp4')
      },
      mutedOneShow: false,
      mutedTwoShow: false,
      mutedThreeShow: false,
      carouselImgList: [
        { id: 2, imgSrc: require('../assets/lunbo/bannerx2.png'), mobileImg: require('../assets/mobile/mobileBanner2.png') },
        // { id: 4, imgSrc: require('../assets/pc_yexfc.png'), mobileImg: require('../assets/mb_yexfc.png') },
        { id: 3, imgSrc: require('../assets/lunbo/banner2x2.png'), mobileImg: require('../assets/mobile/mobileBanner3.png') }

      ],
      lunbo: [
        {
          id: 1,
          iconImg: require('../assets/newImg/火星计划/hxjh_icon.png'),
          imgSrc: require('../assets/newImg/火星计划/hxjh.png'),
          title: '火星计划',
          content:
            '《火星计划》是一款星际题材的联机末日生存射击游戏，包含了穿越、冒险、解谜等多样的游戏元素，在游戏中玩家可以体验到不一样的畅快射击，刺激紧张的求生感受！更有丰富剧情，世界BOSS，萌宠佣兵，星际角斗场，多样枪械，不同手感，百搭技能，超高游戏性！'
        },
        {
          id: 2,
          iconImg: require('../assets/newImg/别吃鸡/t019f519301cfe4b470.png'),
          imgSrc: require('../assets/newImg/别吃鸡//960x500.png'),
          title: '别吃鸡',
          content:
            '《别吃鸡》是一款休闲小游戏，游戏中玩家会遇到各种不同的敌人，需要搭配不一样的武器，才能更快的将敌人收服，通过别吃鸡全武器合成攻略图一览可以了解每个武器的合成进攻，根据自己手中所拥有的武器合成强大的武器来对抗一波又一波的敌人。'
        },
        {
          id: 3,
          iconImg: require('../assets/newImg/幻象忍者/icon补直角.png'),
          imgSrc: require('../assets/newImg/幻象忍者/1024x500.png'),
          title: '幻象忍者',
          content:
            '《幻象忍者》是一款闯关游戏。在游戏中玩家需要操作主角，想法设法的让他跳过关卡中的一个个障碍和陷阱，游戏中会出现一些难以通过的障碍，这个时候就需要利用一些有趣的特殊道具，利用玩家灵活的操作来通过关卡。在游戏中通过的关卡越多，玩家在排行榜中的排名就越高，在众多玩家中彰显自身的实力。'
        }
        // {
        //   id: 4,
        //   iconImg: require('../assets/newImg/别找茬/来找茬512x512.png'),
        //   imgSrc: require('../assets/newImg/别找茬/biezhao.png'),
        //   title: '别找茬',
        //   content:
        //     '《别找茬》是一款刺激有趣的找茬游戏。乱糟糟的房间，各种急需的东西夹杂在一堆物品当中，需要帮着急的小妹妹把东西找出来！看看你能找到多少,游戏有限定时间,考验你眼力的时候到了,而且加入了闯关模式，丰富关卡待你探索，还等什么,一起来挑战吧。'
        // }
        // {
        //   id: 5,
        //   iconImg: require('../assets/newImg/笑傲天龙/Icon-1 (2).png'),
        //   imgSrc: require('../assets/newImg/笑傲天龙/xiaoao.png'),
        //   title: '笑傲天龙',
        //   content:
        //     '《笑傲天龙》是一款仙侠类手机客户端大型网络(MMORPG)游戏。游戏拥有一系列独创特色玩法，其中妙趣横生的奇幻世界，唯美怡人的仙林奇境，带给玩家的不仅是新鲜感，更是能长期陪伴玩家，让玩家突破自我，传递真能量，同时也能防止玩家沉迷而采取离线挂机模式的一款仙侠手机网络游戏。'
        // }
      ],
      index: 1,
      BottomCurrentIndex: 0,
      windowWidth: document.documentElement.clientWidth,
      codeImg:[
        {
          name:'火星计划',
          img:require('../assets/code/hxjh.png')
        },
        {
          name: '别吃鸡',
          img: ""
        },
        {
          name: '幻想忍者',
          img: ""
        }
      ]
    }
  },
  methods: {
    bottomBtn(index) {
      this.BottomCurrentIndex = index
    },
    leftPlayIconBtn() {
      console.log(11)
      this.mutedOneShow = true
      this.$refs.videoOne.play()
    },
    topPlayIconBtn() {
      this.mutedTwoShow = true
      this.$refs.videoTwo.play()
    },
    bottomPlayIconBtn() {
      this.mutedThreeShow = true
      this.$refs.videoThree.play()
    },
    // moreBtn () {
    //   this.$router.push('/game')
    // },
    contactBtn() {
      this.$router.push('/contact')
    },
    downFn(item) {
      console.log(item);
      
      if (item.title!=='火星计划') {
        this.$message({
          showClose: true,
          message: '敬请期待',
          offset: 50,
          duration: 1000
        })
      }else {
        window.open('https://www.taptap.cn/app/378080', '_blank');
      }
      // else if{
      //   return window.open('https://h5pay.zdjoys.com/download/static/images/hxjh.apk', '_self');
      // }
      // if (document.documentElement.clientWidth < 992) {
      //     // return window.open('https://h5pay.zdjoys.com/download/static/images/hxjh.apk', '_self');
      //     return window.open('https://h5pay.zdjoys.com/download/static/images/hxjh.apk', '_self');
      //     // https://www.taptap.cn/app/378080
      //   }
      // this.dialogVisible = true
      // console.log(document.documentElement.clientWidth);
      
    },
    close() {
      this.dialogVisible = false
    }
  },
  watch: {
    // windowWidth: {
    //   handler (newVal, oldVal) {
    //     console.log(newVal, oldVal)
    //   }
    // }
  },
  created() {
    window.scrollTo(0, 0)
    // console.log('window.innerWidth', windowWidth)
  }
}
</script>

<style lang="scss" scoped>
.box {
  padding: 50px 0 0 0;
}

// pc端
@media (min-width: 992px) {

  .imgbox {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    .image {
      width: 200px;
      height: 200px;

      .img {
        width: 100%;
        height: 100%;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  // 轮播图

  .carousel-box {
    width: 100%;

    // display: flex;
    // justify-content: center;
    .carousel {
      // width: 1500px;
      width: 1400px;
      height: 500px;
      margin: 0 auto;

      ::v-deep .el-carousel__arrow {
        background-color: rgba(31, 45, 61, 0.5);
        font-weight: 700;
      }

      ::v-deep .el-carousel__container {
        height: 500px !important;
      }

      ::v-deep .el-carousel__button {
        background-color: #ff8714;
      }

      ::v-deep .el-carousel__indicators.el-carousel__indicators--horizontal {
        bottom: 40px;

        .el-carousel__button {
          width: 59px;
          height: 4px;
        }
      }

      .text-box-parent {
        position: relative;

        .text-box {
          position: absolute;
          // background-color: red;

          z-index: 99;
          top: 50%;
          left: 150px;
          transform: translateY(-50%);

          .one {
            font-size: 30px;
            color: #29262d;
            margin-bottom: 15px;
            letter-spacing: 1px;
          }

          .two {
            font-size: 36px;
            color: #29262d;
            margin-bottom: 15px;
            letter-spacing: 2px;
          }

          .three {
            text-align: center;
            line-height: 30px;
            width: 100px;
            height: 30px;
            background-color: #ff8714;
            font-size: 14px;
            color: #fff;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }

  // 关于掌动
  .about {
    @media (min-width: 992px) {
      height: 500px;
    }

    // background-color: #ffffff;
    // background-color:aqua;

    display: flex;
    justify-content: center;

    //pc端关于内容样式
    @media (min-width: 992px) {
      .box {
        text-align: center;
        width: 1200px;

        .one {
          // background-color: antiquewhite;
          // text-align: center;
          // background-color: aliceblue;
          // line-height: 100px;
          // width: 200px;
          // height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon-title {
            width: 20px;
            height: 33px;

            // background-color:#ff8714 ;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .title {
            text-align: center;
            line-height: 25px;
            font-size: 25px;
            color: #000000;
            margin: 0 8px;
            // position: relative;

            // &::before {
            //   content: ' ';
            //   position: absolute;
            //   display: block;
            //   width: 5px;
            //   height: 5px;
            //   border-radius: 50%;
            //   background-color: #ff8714;
            //   top: 50%;
            //   transform: translateY(-50%);
            //   left: 595px;
            // }

            // &::after {
            //   content: ' ';
            //   position: absolute;
            //   display: block;
            //   width: 5px;
            //   height: 5px;
            //   border-radius: 50%;
            //   background-color: #ff8714;
            //   top: 50%;
            //   transform: translateY(-50%);
            //   right: 595px;
            // }
          }
        }

        .two {
          color: #a7a7a7;
          font-size: 25px;
          font-family: Fontsstyle1;
          font-weight: 300;
          letter-spacing: 2px;
        }

        .three {
          margin: 30px 0;
          font-size: 36px;
        }

        .four {
          font-size: 16px;

          .four-item:nth-child(2) {
            margin: 10px 0;
          }
        }

        .mobile-four {
          display: none;
        }

        .five {
          display: flex;
          justify-content: center;
          margin-top: 40px;

          .five-item:nth-child(2) {
            margin: 0 100px;
          }

          .five-item-title {
            // font-weight: 700;
            color: #000000;
            font-size: 45px;
          }

          .five-item {
            font-size: 16px;
          }
        }
      }
    }
  }

  //产品介绍
  .product {
    display: flex;
    justify-content: center;
    // background-color: aliceblue;
    height: 700px;
    // padding: 0 10% 0 10%;
    // background-color: brown;

    .box {
      text-align: center;
      width: 100%;
      width: 1400px;
      padding: 0;
      // background-color: red;

      .one {
        // background-color: antiquewhite;
        // text-align: center;
        // background-color: aliceblue;
        // line-height: 100px;
        // width: 200px;
        // height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-title {
          width: 20px;
          height: 33px;

          // background-color:#ff8714 ;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          text-align: center;
          line-height: 25px;
          font-size: 25px;
          color: #000000;
          margin: 0 8px;
          // position: relative;

          // &::before {
          //   content: ' ';
          //   position: absolute;
          //   display: block;
          //   width: 5px;
          //   height: 5px;
          //   border-radius: 50%;
          //   background-color: #ff8714;
          //   top: 50%;
          //   transform: translateY(-50%);
          //   left: 595px;
          // }

          // &::after {
          //   content: ' ';
          //   position: absolute;
          //   display: block;
          //   width: 5px;
          //   height: 5px;
          //   border-radius: 50%;
          //   background-color: #ff8714;
          //   top: 50%;
          //   transform: translateY(-50%);
          //   right: 595px;
          // }
        }
      }

      .two {
        color: #a7a7a7;
        font-size: 25px;
        font-family: Fontsstyle1;
        font-weight: 300;
        letter-spacing: 2px;
        margin-bottom: 40px;
      }

      .content {
        position: relative;

        .content-top {
          display: flex;
          // justify-content: space-between;
          height: 450px;
          // width: 100%;
          // opacity: 1;
          display: none;

          &.active {
            // opacity: 1;

            // height: 300px;
            // width: 100%;
            display: flex;
            // display: block;
          }

          .left {
            // flex: 1;
            width: 960px;
            height: 100%;

            // img {
            //   width: 100%;
            //   height: 100%;
            // }
            // background-color: aqua;
          }

          .right {
            width: 600px;
            height: 100%;
            // background-color: red;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .right-text-box {
              margin-left: 200px;

              .right-title {
                margin-left: 135px;
                line-height: 80px;
                width: 200px;
                height: 80px;
                font-size: 43px;
                // font-weight: 700;
                // background-color: blue;
              }

              .line-box {
                display: flex;
                margin-left: 238px;

                .line1 {
                  width: 15px;
                  height: 3px;
                  margin-right: 10px;
                  background-color: #ff8714;
                }

                .line2 {
                  width: 60px;
                  height: 3px;
                  background-color: #ff8714;
                }
              }

              .right-content {
                text-align: left;
                margin-top: 10px;
                width: 434px;
                height: 200px;
                margin-left: -91px;
                text-indent: 2em !important;
                font-size: 15px;
                // background-color: aqua;
              }

              .more {
                width: 100px;
                height: 40px;
                background-color: #ff8714;
                color: #fff;
                text-align: center;
                line-height: 40px;
                border-radius: 3px;
                font-size: 16px;
                cursor: pointer;

                &:hover {
                  background-color: rgba(255, 135, 20, 0.8);
                }

                span {
                  margin-left: 3px;
                }
              }
            }
          }
        }

        .content-bottom {
          display: flex;
          // justify-content: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 491px;

          .content-bottom-item {
            cursor: pointer;
            border-radius: 5px;
            width: 60px;
            height: 60px;
            // background-color: green;
            margin-right: 20px;

            img {
              border-radius: 5px;
              object-fit: fill;
              width: 100%;
              height: 100%;
            }

            &.active {
              border: 2px solid #ff8714;
            }
          }
        }
      }
    }
  }

  //视频
  .video {
    display: flex;
    justify-content: center;
    // background-color: red;
    height: 630px;
    // padding: 0 100px 0 100px;
    // background-color: brown;

    .box {
      text-align: center;
      width: 100%;
      padding: 0;
      width: 1400px;
      // background-color: red;

      .one {
        // background-color: antiquewhite;
        // text-align: center;
        // background-color: aliceblue;
        // line-height: 100px;
        // width: 200px;
        // height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-title {
          width: 20px;
          height: 33px;

          // background-color:#ff8714 ;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          text-align: center;
          line-height: 25px;
          font-size: 25px;
          color: #000000;
          margin: 0 8px;
          // position: relative;

          // &::before {
          //   content: ' ';
          //   position: absolute;
          //   display: block;
          //   width: 5px;
          //   height: 5px;
          //   border-radius: 50%;
          //   background-color: #ff8714;
          //   top: 50%;
          //   transform: translateY(-50%);
          //   left: 595px;
          // }

          // &::after {
          //   content: ' ';
          //   position: absolute;
          //   display: block;
          //   width: 5px;
          //   height: 5px;
          //   border-radius: 50%;
          //   background-color: #ff8714;
          //   top: 50%;
          //   transform: translateY(-50%);
          //   right: 595px;
          // }
        }
      }

      .two {
        color: #a7a7a7;
        font-size: 25px;
        font-family: Fontsstyle1;
        font-weight: 300;
        letter-spacing: 2px;
        margin-bottom: 40px;
      }

      .content {
        display: flex;
        justify-content: space-between;

        .left {
          position: relative;
          width: 857px;
          height: 500px;
          // background-color: aqua;

          .left-bottom-shadow {
            position: absolute;
            bottom: 0;
            left: 0;
            // width: 789px;
            width: 100%;
            height: 50px;
            z-index: 99;
            background-color: rgba(20, 42, 61, 0.3);

            .title {
              color: #fff;
              font-size: 20px;
              text-align: left;
              line-height: 50px;
              font-weight: 300;
            }
          }

          .left-playIcon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100px;
            height: 100px;

            // background-color: red;
            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
          }
        }

        .right {
          // width: 400px;
          // // height: 150px;
          // height: 100%;
          // background-color: yellow;
          margin-left: 10px;

          .right-top {
            width: 529px;
            height: 242px;
            // background-color: burlywood;
            margin-bottom: 15px;
            position: relative;

            .left-bottom-shadow {
              position: absolute;
              bottom: 0;
              left: 0;
              // width: 789px;
              width: 100%;
              height: 50px;
              z-index: 99;
              background-color: rgba(20, 42, 61, 0.3);

              .title {
                color: #fff;
                font-size: 16px;
                text-align: left;
                line-height: 50px;
                font-weight: 300;
              }
            }

            .left-playIcon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 70px;
              height: 70px;

              // background-color: red;
              img {
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
            }
          }

          .right-bottom {
            width: 529px;
            height: 242px;
            // background-color: blue;
            position: relative;

            .left-bottom-shadow {
              position: absolute;
              bottom: 0;
              left: 0;
              // width: 789px;
              width: 100%;
              height: 50px;
              z-index: 99;
              background-color: rgba(20, 42, 61, 0.3);

              .title {
                color: #fff;
                font-size: 16px;
                text-align: left;
                line-height: 50px;
                font-weight: 300;
              }
            }

            .left-playIcon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 70px;
              height: 70px;

              // background-color: red;
              img {
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  //商务合作
  .cooperation {
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #036f9a;
    background-image: linear-gradient(to right top, #0d496c, #047895, #01bdb0);
    height: 300px;
    margin: 30px 0 50px 0;
    letter-spacing: 8px;

    // padding: 0 100px 0 100px;
    // background-color: brown;
    .box {
      text-align: center;
      padding: 0;

      .textOne {
        font-size: 40px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
      }

      .textTwo {
        margin-top: -32px;
        font-size: 40px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }

      .btn {
        padding: 0;

        .btn-box {
          width: 127px;

          height: 30px;
          margin: 20px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          // justify-content: center;
          background-color: #ff8714;
          color: #ffebd8;
          letter-spacing: 1px;
          font-size: 14px;
          border-radius: 3px;

          .phone-icon {
            width: 15px;
            height: 15px;
            margin: -8px 0 0 3px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  // 通栏底部

  .common-bottom {
    height: 250px;
    background-color: #ff8714;

    .mobile-box {
      display: none;
    }

    .box {
      text-align: center;
      padding-top: 60px;

      .line {
        // width: 1920px;
        width: 100%;
        height: 1px;
        background: linear-gradient(225deg, rgba(255, 255, 255, 0) 0%, #ffffff 51%, rgba(255, 255, 255, 0) 100%);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }

      .title {
        color: #ffebd8;
        font-size: 25px;
        margin: 20px 0;
      }

      .textOne {
        color: #ffebd8;
        font-size: 14px;
        margin-bottom: 15px;
      }

      .textTwo {
        color: #ffebd8;
        font-size: 14px;
      }
    }
  }
}

// 移动端
@media (max-width: 992px) {
  img {
    width: 100%;
    height: 100%;
  }

  ::v-deep .el-carousel__button {
    background-color: #ff8714;
  }

  .icon-title {
    display: none;
  }

  //轮播图
  .carousel-box {
    .text-box-parent {
      // z-index: -1;
    }

    ::v-deep .el-carousel__container {
      // height: 250px !important;
    }
  }

  .text-box-parent {
    position: relative;

    .text-box {
      position: absolute;
      // background-color: red;
      z-index: 999;
      top: 50%;
      left: 69px;
      transform: translateY(-50%);

      .one {
        font-size: 12px;
        color: #191919;
        margin-bottom: 5px;
        font-weight: 500;
      }

      .two {
        font-size: 14px;
        color: #191919;
        margin-bottom: 5px;
        letter-spacing: 2px;
        font-weight: 500;
      }

      .three {
        text-align: center;
        line-height: 30px;
        width: 70px;
        height: 30px;
        background-color: #ff8714;
        font-size: 12px;
        color: #fff;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .about {
    @media (max-width: 992px) {
      height: 250px;
    }

    display: flex;
    justify-content: center;
    // background-color: aqua;

    .box {
      padding: 20px 0 0 0;
      text-align: center;
      // width: 1200px;

      .one {
        // background-color: antiquewhite;
        // text-align: center;
        // background-color: aliceblue;
        // line-height: 100px;
        // width: 200px;
        // height: 100px;

        .title {
          font-size: 20px;
          color: #ff8714;
          position: relative;

          &::before {
            content: ' ';
            position: absolute;
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #ff8714;
            top: 50%;
            transform: translateY(-50%);
            left: 36%;
            // transform: translatex(-50%);

          }

          &::after {
            content: ' ';
            position: absolute;
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #ff8714;
            top: 50%;
            transform: translateY(-50%);
            right: 36%;
          }
        }
      }

      .two {
        color: #a7a7a7;
        font-size: 20px;
        font-family: Fontsstyle1;
        font-weight: 300;
        // letter-spacing: 2px;
      }

      .three {
        margin: 5px 0;
        font-size: 18px;
        color: #000000;
        // font-size: 32px;
      }

      .four {
        display: none;
      }

      .mobile-four {
        font-size: 12px;

        .four-item {
          text-align: center;
          padding: 0 20px;
          text-indent: 2em;
          font-size: 12px;
        }

        .four-item:nth-child(2) {
          margin: 10px 0;
        }
      }

      .five {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        font-size: 12px;

        .five-item:nth-child(2) {
          margin: 0 25px;
        }

        .five-item-title {
          // font-weight: 700;
          color: #000000;
          font-size: 20px;
        }
      }
    }
  }

  //产品介绍
  .product {
    img {
      width: 100%;
      height: 100%;
    }

    display: flex;
    justify-content: center;
    // background-color: aliceblue;
    height: 280px;
    padding: 0 20px;
    // background-color: brown;

    .box {
      text-align: center;
      width: 100%;
      padding: 0;
      // background-color: red;

      .one {
        // background-color: antiquewhite;
        // text-align: center;
        // background-color: aliceblue;
        // line-height: 100px;
        // width: 200px;
        // height: 100px;

        .title {
          font-size: 20px;
          color: #ff8714;
          position: relative;

          &::before {
            content: ' ';
            position: absolute;
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #ff8714;
            top: 50%;
            transform: translateY(-50%);
            left: 34.2%;
          }

          &::after {
            content: ' ';
            position: absolute;
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #ff8714;
            top: 50%;
            transform: translateY(-50%);
            right: 34.2%;
          }
        }
      }

      .two {
        color: #a7a7a7;
        font-size: 20px;
        margin-bottom: 10px;
        font-family: Fontsstyle1;
        font-weight: 300;
        // letter-spacing: 2px;
      }

      .content {
        position: relative;

        .content-top {
          display: flex;
          // justify-content: space-between;
          height: 150px;
          // width: 100%;
          // opacity: 1;
          display: none;

          &.active {
            // opacity: 1;

            // height: 100px;
            // width: 100%;
            display: flex;
            // display: block;
          }

          .left {
            // flex: 1;
            width: 595px;
            height: 100%;

            // img {
            //   width: 100%;
            //   height: 100%;
            // }
            // background-color: aqua;
          }

          .right {
            width: 278px;
            height: 100%;
            // background-color: red;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .right-text-box {
              .right-title {
                font-size: 20px;
                margin-bottom: 5px;
                // margin-left: 135px;
                // line-height: 80px;
                // width: 100px;
                // height: 20px;
                // font-size: 16px;
                // font-weight: 700;
                // background-color: blue;
              }

              .line-box {
                display: flex;
                margin-left: 47px;
                // margin-left: 238px;

                .line1 {
                  width: 9px;
                  height: 1px;
                  margin-right: 10px;
                  background-color: #ff8714;
                }

                .line2 {
                  width: 30px;
                  height: 1px;
                  background-color: #ff8714;
                }
              }

              .right-content {
                display: none;
              }

              .more {
                margin: 0 auto;
                margin-top: 20px;
                width: 50px;
                height: 25px;
                background-color: #ff8714;
                color: #fff;
                text-align: center;
                line-height: 25px;
                border-radius: 2px;
                font-size: 12px;
                cursor: pointer;

                &:hover {
                  background-color: rgba(255, 135, 20, 0.8);
                }

                span {
                  margin-left: 1px;
                }
              }
            }
          }
        }

        .content-bottom {
          display: flex;
          // justify-content: center;
          position: absolute;
          align-items: center;
          // left: 64px;
          top: 170px;
          left: 50%;
          transform: translateX(-50%);
          // height: 60px;

          .content-bottom-item {
            width: 40px;
            height: 40px;
            // background-color: green;
            margin-right: 10px;
            border-radius: 3px;
            // border: 1px solid red;
            display: flex;
            align-items: center;

            img {
              // width: 100%;
              // height: 100%;
              width: 38px;
              height: 38px;
              // border-radius: 3px;
              // object-fit: fill;
            }

            &.active {
              border: 1px solid #ff8714;
            }
          }
        }
      }
    }
  }

  //游戏视频
  .video {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    // background-color: red;
    // height: 500px;
    padding: 0 20px 0 20px;
    // background-color: brown;

    .box {
      text-align: center;
      width: 100%;
      padding: 0;
      // background-color: red;

      .one {
        // background-color: antiquewhite;
        // text-align: center;
        // background-color: aliceblue;
        // line-height: 100px;
        // width: 200px;
        // height: 100px;

        .title {
          font-size: 20px;
          color: #ff8714;
          position: relative;

          &::before {
            content: ' ';
            position: absolute;
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #ff8714;
            top: 50%;
            transform: translateY(-50%);
            left: 34.2%;
          }

          &::after {
            content: ' ';
            position: absolute;
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #ff8714;
            top: 50%;
            transform: translateY(-50%);
            right: 34.2%;
          }
        }
      }

      .two {
        color: #a7a7a7;
        font-size: 20px;
        margin-bottom: 10px;
        font-family: Fontsstyle1;
        font-weight: 300;
        // letter-spacing: 2px;
      }

      .content {
        // display: flex;

        .left {
          position: relative;
          width: 100%;
          height: 500px;
          // background-color: aqua;

          .left-bottom-shadow {
            position: absolute;
            bottom: 0;
            left: 0;
            // width: 789px;
            width: 100%;
            height: 30px;
            z-index: 999;
            background-color: rgba(20, 42, 61, 0.3);

            .title {
              color: #fff;

              font-size: 12px;
              text-align: left;
              line-height: 30px;
              font-weight: 300;
            }
          }

          .left-playIcon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50px;
            height: 50px;

            // background-color: red;
            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
          }
        }

        .right {
          // display: flex;
          width: 100%;
          justify-content: space-between;
          margin-top: 15px;
          // width: 400px;
          // // height: 150px;
          // height: 100%;
          // background-color: yellow;
          // margin-left: 5px;

          .right-top {
            width: 100%;
            height: 500px;
            // background-color: burlywood;
            margin-bottom: 15px;
            // margin-right: 10px;
            position: relative;

            .left-bottom-shadow {
              position: absolute;
              bottom: 0;
              left: 0;
              // width: 789px;
              width: 100%;
              height: 30px;
              z-index: 999;
              background-color: rgba(20, 42, 61, 0.3);

              .title {
                color: #fff;

                font-size: 12px;
                text-align: left;
                line-height: 30px;
                font-weight: 300;
              }
            }

            .left-playIcon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 50px;
              height: 50px;

              // background-color: red;
              img {
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
            }
          }

          .right-bottom {
            width: 100%;
            height: 500px;
            // background-color: blue;
            position: relative;

            .left-bottom-shadow {
              position: absolute;
              bottom: 0;
              left: 0;
              // width: 789px;
              width: 100%;
              height: 30px;
              z-index: 999;
              background-color: rgba(20, 42, 61, 0.3);

              .title {
                color: #fff;
                font-size: 12px;
                text-align: left;
                line-height: 30px;
                font-weight: 300;
              }
            }

            .left-playIcon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 50px;
              height: 50px;

              // background-color: red;
              img {
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  //商务合作
  .cooperation {
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #036f9a;
    background-image: linear-gradient(to right top, #0d496c, #047895, #01bdb0);
    height: 150px;
    margin: 15px 0;
    letter-spacing: 4px;

    // padding: 0 100px 0 100px;
    // background-color: brown;
    .box {
      text-align: center;
      padding: 0;

      .textOne {
        font-size: 20px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
      }

      .textTwo {
        margin-top: -16px;
        font-size: 20px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }

      .btn {

        // padding: 0;
        .btn-box {
          width: 86px;
          height: 30px;
          // margin: 20px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          // justify-content: center;
          background-color: #ff8714;
          color: #ffebd8;
          letter-spacing: 1px;
          font-size: 12px;
          border-radius: 3px;

          .phone-icon {
            width: 12px;
            height: 12px;
            margin: -8px 0 0 3px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  // 通栏底部

  .common-bottom {
    height: 150px;
    background-color: #ff8714;

    .box {
      display: none;
    }

    .mobile-box {
      padding: 24px 0 0 0;
      text-align: center;

      .line {
        // width: 1920px;
        width: 100%;
        height: 1px;
        background: linear-gradient(225deg, rgba(255, 255, 255, 0) 0%, #ffffff 51%, rgba(255, 255, 255, 0) 100%);
        // border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }

      .title {
        color: #ffebd8;
        font-size: 16px;
        margin: 5px 0;
      }

      .textOne {
        color: #ffebd8;
        font-size: 10px;
        transform: scale(0.83333);

        // margin-bottom: 15px;
      }

      .textTwo {
        color: #ffebd8;
        font-size: 10px;
        transform: scale(0.83333);
      }
    }
  }
}
</style>
