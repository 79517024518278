import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navpopupShow: false,
    index: 0,
    userInfo: JSON.parse(localStorage.getItem('userInfo'))||{}
  },
  mutations: {
    setUserInfo(state, val){
      state.userInfo=val
      localStorage.setItem('userInfo', JSON.stringify(val))
    },
    setNavpopupShow (state, val) {
      state.navpopupShow = val
    },
    setIndex (state, val) {
      state.index = val
    },
    setQuit(state, val){
      state.userInfo ={}
      localStorage.removeItem('userInfo')
    }
  },
  actions: {
  },
  modules: {
  }
})
