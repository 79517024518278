import axios from 'axios'
import store from '../store/index'
// import { Message } from 'element-ui'
const request = axios.create({
  baseURL: 'https://zd.zdjoys.com/',

  timeout: 5000
})

// 请求拦截器
request.interceptors.request.use(
  config => {
    // const token = store.state.user.token
    // const headersObj = store.state.user.headersObj
    // if (token) {
    //   config.headers = {
    //     'Token': token,
    //     'Token-key': headersObj['set-token-key'],
    //     'Refresh-Token': headersObj['set-refresh-token']
    //   }
    //   // config.headers['Token'] = headersObj['set-token']
    //   // config.headers['Token-key'] = headersObj['set-token-key']
    //   // config.headers['Refresh-Token'] = headersObj['set-refresh-token']
    // }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  response => {
    
    return response
  },
  error => {
  
    return Promise.reject(error)
  }
)

export default request
