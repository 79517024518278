<template>
  <div >
    <Nav class="nav" :navList="navList"></Nav>
    <div>
        <router-view></router-view>
    </div>
    <Bottom/>
  </div>
</template>

<script>
import Nav from './components/Nav.vue'
import Bottom from './components/bottom.vue'
export default {
  data () {
    return {
      navList: [
        {
          title: '首页',
          id: 0,
          src: '/home'
        },
        // {
        //   title: '关于掌动',
        //   id: 1,
        //   src: '/about'
        // },
        // {
        //   title: '旗下游戏',
        //   id: 2,
        //   src: '/game'
        // },
        {
          title: '加入我们',
          id: 3,
          src: '/join'
        },
        {
          title: '联系我们',
          id: 4,
          src: '/contact'
        }

      ]
    }
  },
  components: {
    Nav,
    Bottom
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 992px) {
  .box{
    // padding: 50px 0 0 0;
  }
  // ::v-deep .nav {
  //   position: absolute;
  //   z-index: 999;
  //   width: 100%;
  // }

}
</style>
