<template>
  <div class="box">
    <!-- 通栏底部 -->
    <div class="common-bottom">
      <div class="box">
        <div class="line"></div>
        <div class="title">掌动游戏，快乐生活！</div>
        <div class="textOne">杭州掌动科技股份有限公司 地址：杭州市滨江区滨盛路1777号萧宏大厦19楼 ©2013-2023 zdjoys.com，All Rights Reserve</div>
        <div class="textTwo">增值电信业务许可证：浙B2-20140348 网络文化经营许可证：浙网文[2017]3458-143号</div>
        <div class="textTwo" style="cursor: pointer;" @click="pcOutLink1">ICP备案号:浙B2-20140348-1</div>
        <div class="textTwo"  style="cursor: pointer;"  @click="pcOutLink2">
          <img style="width: 15px;height: 15px;" src="../assets/newImg/zjga.png" alt="">
          浙公网安备 ： 33010802003143号
        </div>
        <div class="imgBox" style="margin-top: 10px;">
          <img style="margin-right: 10px;" src="../assets/newImg/link_1.jpg" alt="">
          <img src="../assets/newImg/link_2.jpg" alt="">
        </div>
      </div>
      <!-- 移动端 -->
      <div class="mobile-box">
        <div class="line"></div>
        <div class="title">掌动游戏，快乐生活！</div>
        <div class="textOne">杭州掌动科技股份有限公司</div>
        <div class="textOne">地址：杭州市滨江区滨盛路1777号萧宏大厦19楼</div>
        <!-- ©2013-2020 zdjoys.com，All Rights Reserve -->
        <div class="textTwo">增值电信业务许可证：</div>
        <div class="textTwo">浙B2-20140348 网络文化经营许可证：浙网文[2017]3458-143号</div>
        <div class="textTwo" @click="pcOutLink1">ICP备案号:浙B2-20140348-1</div>
        <div class="textTwo" @click="pcOutLink2">
          <img style="width: 15px;height: 15px;" src="../assets/newImg/zjga.png" alt="">
          浙公网安备 ： 33010802003143号
        </div>
        <div class="imgBox" style="margin-top: 10px;">
          <img style="margin-right: 10px;width:80px;height: 35px;" src="../assets/newImg/link_1.jpg" alt="">
          <img style="width:80px;height: 35px;" src="../assets/newImg/link_2.jpg" alt="">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    pcOutLink1 () {
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')
    },
    pcOutLink2 () {
      window.open('https://www.beian.gov.cn/portal/recordQuery?token=33010802003143', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 992px) {
  // 通栏底部

  .common-bottom {
    height: 300px;
    background-color: #ff8714;

    .mobile-box {
      display: none;
    }

    .box {
      text-align: center;
      padding-top: 40px;

      .line {
        // width: 1920px;
        width: 100%;
        height: 1px;
        background: linear-gradient(225deg, rgba(255, 255, 255, 0) 0%, #ffffff 51%, rgba(255, 255, 255, 0) 100%);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }

      .title {
        color: #ffebd8;
        font-size: 25px;
        margin: 20px 0;
      }

      .textOne {
        color: #ffebd8;
        font-size: 14px;
        // margin-bottom: 15px;
      }

      .textTwo {
        color: #ffebd8;
        font-size: 14px;
        margin: 5px 0;
      }
    }
  }
}
@media (max-width: 750px) {
  .common-bottom {
    height: 250px;
    background-color: #ff8714;

    .box {
      display: none;
    }

    .mobile-box {
      padding: 24px 0 0 0;
      text-align: center;

      .line {
        // width: 1920px;
        width: 100%;
        height: 1px;
        background: linear-gradient(225deg, rgba(255, 255, 255, 0) 0%, #ffffff 51%, rgba(255, 255, 255, 0) 100%);
        // border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }

      .title {
        color: #ffebd8;
        font-size: 16px;
        margin: 5px 0;
      }

      .textOne {
        color: #ffebd8;
        font-size: 10px;
        transform: scale(0.83333);

        // margin-bottom: 15px;
      }

      .textTwo {
        color: #ffebd8;
        font-size: 10px;
        transform: scale(0.83333);
      }
    }
  }
}
</style>
