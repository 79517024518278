<template>
  <div>
    欢迎您选择由杭州掌动科技股份有限公司及旗下各关联公司（以下统称“掌动”、 “掌动科技”或“我们”）提供的掌动科技游戏！我们承诺，对于使用我们的产品和服务（以下统称“掌动服务”）
    的用户（以下统称“用户”或“您”），在接受服务过程中，提供给我们或我们收集的关于您的相关信息，我们深知个人信息对您的重要性，会尽全力保护您的信息和隐私安全。本《隐私政策》就您向我们提供的任何资料，告知您所拥有的选择和我们如何处理该等资料。

    请在使用我们的产品或服务前，仔细阅读并了解本《隐私政策》，特别是加粗部分内容。一旦您使用我们的服务，即表示您已充分理解并同意我们可按照本《隐私政策》（按最新修订版）收集、使用和分享您的资料。需要提醒您注意，本隐私政策仅适用于我们所收集的信息，不适用于通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息以及通过在我们服务中进行广告服务的其他公司或机构所收集的信息。

    除《掌动科技用户服务协议》外，我们还将通过《隐私政策》（“本协议”）向您进一步细化说明您在使用掌动科技游戏时我们收集、使用、存储和共享个人信息的情况，以及您所享有的相关权利等事宜：


    一、我们如何收集和使用信息

    二、我们如何使用COOKIES或同类技术

    三、我们如何保留、储存和保护信息

    四、我们可能分享、转让和披露的信息

    五、您的权利

    六、协议变更

    七、未成年人保护

    八、联系我们

    九、其他


    一、我们如何收集和使用信息


    1.我们收集的信息

    在您使用掌动科技游戏服务的过程中，我们根据合法、正当、必要的原则，收集实现产品功能所需的信息。我们收集您的信息主要是为了您和其他用户能够更容易和更满意地使用掌动服务。

    掌动科技游戏会按照如下方式收集您在使用服务时主动提供的或因为使用服务而产生的信息，用以向您提供、优化我们的服务以及保障您的账户安全：

    1.1
    当您注册或使用掌动游戏服务时，您可选择是否授权我们收集和使用您的个人信息，我们在获得您的同意或您主动提供的情况下收集您的网络身份标识信息及个人常用设备信息，用于标记您为掌动游戏的用户。如果您使用微信、QQ及（或）我们认可的其他账号（以下称“第三方账号”）作为游戏账号关联登录掌动游戏的，我们会收集您微信、QQ及第三方账号的唯一标识、头像、昵称，用于保存您的登录信息，以便您在不同设备登录

    如果您使用微信、QQ及（或）第三方账号作为游戏账号关联登录掌动游戏的，为了更好地向您提供游戏服务，改善游戏体验，我们会收集您的QQ、微信以及第三方账号涉及的唯一标识、昵称、头像、好友关系以及您授权的其他信息，以及您在掌动游戏中的相关操作信息、游戏信息等信息（具体包括但不限于您的登录状态、对战信息/状态、成就信息等）进行使用，并可向您本人或其他用户或好友展示。我们可能会视游戏产品具体情况为您提供相关权限，以便您可以对是否展示前述相关信息进行相应设置。

    1.2
    为满足相关法律法规政策及相关主管部门的要求，掌动游戏用户需进行实名认证以继续使用和享受掌动游戏。您可选择是否授权我们收集和使用您的个人信息，我们会在获得您同意或您主动提供的情况下收集您的实名身份信息（包括姓名、身份证号、照片等信息），该信息属于敏感信息，拒绝提供实名身份信息可能会导致您无法登录掌动游戏或在使用掌动游戏过程中受到相应限制。

    1.3
    为保障您正常使用我们的服务，维护游戏基础功能的正常运行，优化游戏产品性能，提升您的游戏体验并保障您的账号安全，您可选择是否授权我们收集和使用您的个人信息，我们在获得您的同意或您主动提供的情况下收集您的设备ID、设备名称、设备类型和版本、系统版本、IP地址、MAC地址、应用ID、网络类型等信息。

    1.4
    当您使用掌动游戏服务时，您可选择是否授权我们收集和使用您的个人信息，我们在获得您的同意或您主动提供的情况下会收集您的游戏日志信息，例如登录日志、物品日志、游戏对局信息、交友记录等，以便您能够在客户端查看您的游戏历史记录，同时用于游戏运营统计分析、客服投诉处理及其他游戏安全分析，并为提升您的游戏体验，我们可能把前述信息同步至该游戏后续版本或您正在使用的我们提供的其他产品中。

    1.5
    当您使用掌动游戏产品的消费功能时，您可选择是否授权我们收集和使用您的个人信息，我们在获得您的同意或您主动提供的情况下收集您的充值记录、消费记录等信息，以便您查询您的交易记录，同时尽最大程度保护您的虚拟物品安全。充值记录、消费记录属于敏感信息，收集上述信息为实现掌动游戏产品的消费功能所必须，否则将无法完成交易。

    1.6
    为保障您的游戏账号安全，营造公平、健康及安全的游戏环境，您可选择是否授权我们收集和使用您的个人信息，我们在获得您的同意或您主动提供的情况下收集您的游戏识别信息、硬件及操作系统信息、进程及游戏崩溃记录等信息，以用于打击破坏游戏公平环境或干扰、破坏游戏服务正常进行的行为（如用于检测盗版、扫描外挂、防止作弊等）。

    1.7 当您在游戏中通过文字、图片、语音、视频及其他方式与其他玩家进行互动，我们可能会收集并保存您发送的上述信息内容用于过滤色情、暴力、政治、辱骂、恶意广告等不当内容，以此净化游戏环境，维护健康的上网环境。

    1.8 如您希望通过语音与其他游戏玩家互动，您可选择是否授权我们收集和使用您的个人信息，我们在获得您的同意或您主动提供的情况下，游戏会访问您的麦克风，为您提供语音聊天互动等功能。

    1.9
    如您希望与附近的游戏玩家互动，在您授权同意后，您可选择是否授权我们收集和使用您的个人信息，我们在获得您的同意或您主动提供的情况下会收集您的地理位置信息，用于寻找附近的游戏玩家，以便您与附近的玩家匹配、组队等。地理位置信息属于敏感信息，拒绝提供该信息只会导致您无法实现与附近的游戏玩家互动，但不影响您正常使用掌动游戏的其他功能，您也可以随时取消您的地理位置信息授权。

    1.10 我们启用了适度游戏的提醒功能，您可选择是否授权我们收集和使用您的个人信息，我们在获得您的同意或您主动提供的情况下收集您的游戏在线心跳等信息，以在您过度游戏时发出系统提醒。

    1.11 根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息无需征求您的授权同意：

    1） 与国家安全、国防安全等国家利益直接相关的；与公共安全、公共卫生、公共知情等重大公共利益直接相关的；

    2） 与犯罪侦查、起诉、审判和判决执行等直接相关的；

    3） 出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的；

    4） 所收集的个人信息是您自行向社会公众公开的；

    5） 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；

    6） 根据您要求签订和履行合同所必需的；

    7） 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；

    8） 为开展合法的新闻报道所必需的；

    9） 出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；

    10） 法律法规规定的其他情形。

    1.12 请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收集了您的信息，我们会通过页面提示、交互流程、网站公告等方式另行向您说明信息收集的内容、范围和目的，以征得您的同意。

    目前，除了向第三方调研机构收集游戏调研信息以帮助我们改进游戏产品以及提升服务，以及本指引第1.1条规定的您使用第三方账号登录掌动游戏情形之外，我们不会主动从掌动科技外的第三方获取您的个人信息。如未来为业务发展需要从掌动科技外的第三方间接获取并处理您的个人信息的，我们会严格遵守相关法律法规的规定，要求该第三方征得您的同意并确保其提供的信息的合法性。

    2.我们如何使用信息

    我们严格遵守法律法规的规定以及与用户的约定，按照《隐私政策》所述使用收集的信息，以向您提供更为优质的服务。

    2.1信息使用规则

    我们会按照如下规则使用收集的信息：

    1) 我们会根据我们收集的信息向您提供各项功能与服务，包括基础游戏功能、玩家互动功能、消费功能等；

    2) 我们会根据您使用掌动科技游戏产品的频率和情况、故障信息、性能信息等分析我们产品的运行情况，以确保服务的安全性，并优化我们的产品，提高我们的服务质量。我们不会将我们存储在分析软件中的信息与您提供的个人身份信息相结合。

    2.2 告知变动目的后征得同意的方式

    我们将会在本协议所涵盖的用途内使用收集的信息。如我们使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前，再次向您告知并征得您的明示同意。

    2.3为提高您的安全性，确保操作环境安全与识别会员账号异常状态，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入、插件、挂机等安全风险，更准确地识别违反法律法规或掌动科技相关协议规则的情况，我们可能使用或整合您的用户信息、交易信息、设备信息、有关网络日志等，综合判断您游戏账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。


    二、我们如何使用COOKIES或同类技术


    我们或我们的第三方合作伙伴可能通过COOKIES或同类技术获取和使用您的信息，并将该等信息储存为日志信息。

    Cookie
    是由用户访问的网站向浏览器发送的一小段文本，它通常包含标识符、站点名称以及一些号码和字符。它帮助浏览器记录有关访问活动的信息，例如会话信息、首选语言和其他一些设置。这样，用户在下次访问同样的网站时就能获得更轻松实用的访问体验。
    通过使用COOKIES，我们向用户提供简单易行并富个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让其用户可以受益。比如，为使得用户的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要登录服务时能更加方便快捷。COOKIES能帮助我们确定您连接的页面和内容，您在特定服务上花费的时间和您所选择的服务。
    COOKIES使得我们能更好、更快地为您服务，并且使您在掌动提供的服务上的经历更富个性化。然而，您应该能够控制COOKIES是否以及怎样被你的浏览器接受。请查阅您的浏览器附带的文件以获得更多这方面的信息。

    我们和第三方合作伙伴可能通过COOKIES或同类技术收集和使用您的信息，并将该等信息储存。

    我们使用自己的COOKIES或同类技术，可能用于以下用途：

    1）记住您的身份。例如：COOKIES或同类技术有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供有关您的喜好或其他信息；

    2）提高安全性。我们可利用Cookie或同类技术保障数据和服务的安全性，排查针对我们的产品和服务的作弊、黑客、欺诈行为

    3）分析您使用我们服务的情况。我们可利用COOKIES或同类技术来了解您使用掌动服务进行什么活动、或哪些服务或服务最受欢迎；

    4）广告优化。COOKIES或同类技术有助于我们根据您的信息，向您提供与您相关的广告而非进行普遍的广告投放。

    我们为上述目的使用COOKIES或同类技术的同时，可能将通过COOKIES或同类技术收集的非个人身份信息汇总提供给广告商和其他伙伴，用于分析您和其他用户如何使用掌动服务并用于广告服务。

    掌动服务上可能会有广告商和其他合作方放置的COOKIES或同类技术。这些COOKIES和或同类技术可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。这些第三方COOKIES或同类技术收集和使用该等信息不受本《隐私政策》约束，而是受到其自身的信息保护声明约束，我们不对第三方的COOKIES或同类技术承担责任。

    您可以通过浏览器或用户选择机制拒绝或管理COOKIES或或同类技术。但请您注意，如果您停用COOKIES或同类技术，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。同时，您仍然将收到广告，只是这些广告与您的相关性会降低。


    三、 我们如何保留、储存和保护信息


    3.信息的存储

    3.1 信息存储的方式和期限

    我们会通过安全的方式存储您的信息，包括本地存储（例如利用APP进行数据缓存）、数据库和服务器日志

    一般情况下，我们只会在为实现服务目的所必需的时间内或法律法规规定的条件下存储您的个人信息。

    3.2 信息存储的地域

    我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。

    3.3 产品或服务停止运营时的通知

    当我们的产品或服务发生停止运营的情况时，我们将根据相关法律法规规定进行公告通知，并依法保障您的合法权益。

    4.信息安全

    4.1 安全保护措施

    我们努力为用户的信息安全提供保障，以防止信息的泄露、丢失、不当使用、未经授权访问和披露等。我们使用多方位的安全保护措施，以确保用户的个人信息保护处于合理的安全水平，包括技术保护手段、管理制度控制、安全体系保障等诸多方面。

    我们采用业界领先的技术保护措施。我们使用的技术手段包括但不限于防火墙、加密（例如SSL）、去标识化或匿名化处理、访问控制措施等。此外，我们还会不断加强安装在您设备端的软件的安全能力。例如，我们会在您的设备本地完成部分信息加密工作，以巩固安全传输；我们会了解您设备安装的应用信息和运行的进程信息，以预防病毒、木马等恶意程序。

    我们建立了保障个人信息安全专门的管理制度、流程和组织。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务并进行审计，违反义务的人员会根据规定进行处罚。我们也会审查该管理制度、流程和组织，以防未经授权的人员擅自访问、使用或披露用户的信息。

    我们建议您在使用产品和服务时充分注意对个人信息的保护，我们也会提供多种安全功能来协助您保护自己的个人信息安全。

    4.2 安全事件处置措施

    若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大。安全事件发生后，我们会以公告、发送通知或邮件等形式告知您安全事件的基本情况、我们即将或已经采取的处置措施和补救措施，以及我们对您的应对建议。如果难以实现逐一告知，我们将通过公告等方式发布警示。


    四、我们可能分享、转让和披露的信息


    5.对外分享提供

    除下述情形外，我们不会主动共享、提供或转让您的个人信息至掌动科技外的第三方，如存在其他共享、提供或转让您的个人信息或您需要我们将您的个人信息共享、提供或转让至掌动外的第三方情形时，我们会直接或确认该第三方征得您对上述行为的明示同意。

    1）、维护和改善我们的服务。我们可能向合作伙伴及其他第三方分享您的信息，以帮助我们为您提供更有针对性、更完善的服务；

    2）、实现本《隐私政策》第一条“我们如何收集和使用信息”部分所述目的；

    3）、履行我们在本《隐私政策》或我们与您达成的其他协议中的义务和行使我们的权利；

    4）、向委托我们进行推广的合作伙伴等第三方共享，目的是为了使该等委托方了解推广的覆盖面和有效性。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客；

    5）、应您合法需求，协助处理您与他人的纠纷或争议；

    6）、应您的监护人合法要求而提供您的信息；

    7）、基于学术研究而提供；

    8）、基于符合法律法规的社会公共利益而提供。

    即使有前款规定，如果您使用的掌动科技游戏的开发者为境外主体的，我们可能会将您在游戏中产生的记录或日志信息（如登陆日志、物品日志等）提供给境外的游戏开发者，用于游戏运营统计分析、客服投诉处理及其他游戏安全分析，帮助开发者完善游戏服务，进一步优化用户的游戏体验。另外，我们会在疑似未成年人消费后，尝试联系其监护人，将相关消费记录等信息告知其监护人，进行提醒、确认与处理。

    除本协议以及《掌动科技用户服务协议》另有规定外，我们不会对外公开披露所收集的个人信息。如必须公开披露时，我们会向您告知此次公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明示同意。

    9）、我们合作伙伴及第三方共享您的个人信息情形逐项列举：

    掌动科技提供的网络游戏产品中可能会包含第三方SDK或其他类似的应用程序，如您在我们产品上使用这类由第三方提供的服务时，掌动科技将通过弹窗提示等方式明确告知用户您是否同意将由其直接收集和处理您的信息（如以嵌入代码、插件等形式）。此类服务商收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。掌动科技会努力审查该第三方的业务准入资质，并努力要求该服务商的合法合规性和安全性。为了最大程度保障您的信息安全，我们建议您在使用任何第三方SDK类服务前先行查看其隐私条款。为保障您的合法权益，如您发现这等SDK或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系（0571-56690777）。

    （1）广告类SDK

    掌动接入以下广告联盟，该广告联盟为了向您提供广告内容，可能需要获取您的设备信息（IMEI）。

    <!-- ①、穿山甲---隐私政策：http://www.toutiao.com/privacy_protection/ -->

    （2）支付类SDK

    ①、支付宝---为了实现付款功能，如您选择使用支付宝进行支付的，将调起支付宝SDK，我们仅获取您的付款信息，而不会获取包括支付账号、密码、指纹在内的其他信息。

    <!-- 隐私政策： http://render.alipay.com/p/c/k2cx0tg8 -->

    ②、微信支付---为了实现开通付款功能，如您选择使用微信支付进行支付的，即将调起微信SDK，我们仅获取您的付款信息，而不会获取包括支付账号、密码、指纹在内的其他信息。

    <!-- 隐私政策：http://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy -->

    （3）登录类SDK

    为了实现用户更快捷的登录，我们通过第三方SDK可以快速登录，不会获取您的第三方账号密码及其他信息。

    <!-- ①、QQ---隐私政策： https://qqchat.qq.com/privacy/privacy.html?v=1

    ②、微信---隐私政策：http://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy

    ③、苹果---隐私政策：https://www.apple.com/legal/privacy/szh/

    ④、Gamecenter隐私政策：https://www.apple.com.cn/legal/privacy/szh/ -->

    （4）更新类SDK

    为了实现游戏版本迭代、BUG修复等服务需要，我们通过乐变SDK实施游戏热更新，仅会要求文件权限，不会获取您的信息。

    <!-- 乐变---隐私政策：http://www.loveota.com/register/privacy.html -->

    （5）分享类SDK

    为了实现用户分享和邀请等功能，我们通过第三方SDK实现快速分享，需要获取您的设备信息（IMEI）、剪贴板、位置信息、相册、IDFA，并可能会手机您的手机型号、系统类型、系统版本、设备屏幕、尺寸参数等信息。

    <!-- ①、友盟社会化分享---隐私政策：https://www.umeng.com/page/policy?spm=a211eg.10560647.0.0.540034dcZImivB -->

    （6）数据统计类SDK

    为了对我们的用户数量进行统计，我们接入以下数据统计类SDK，需要获取网络状态、Wifi状态、手机状态等。

    <!-- ①、热云---隐私政策：http://www.trackingio.com/policy.html

    ②、Talkingdata---隐私政策：http://www.talkingdata.com/privacy.jsp -->

    （7）异常分析类SDK

    为了快速发现用户在使用过程中出现的 Crash (崩溃)、Android ANR 和 iOS 卡顿，并根据上报的信息快速定位和解决问题，我们接入了腾讯Bugly SDK，需要获取手机状态、网络状态、读取日志的信息。

    <!-- ①、腾讯Bugly---隐私政策：https://bugly.qq.com/v2/contract -->

    （8）内容、设备风控类SDK

    为了打造绿色健康的游戏环境，防止黑产恶意破坏游戏的行为，维护用户和公司合法权益，我们接入数美科技SDK，需要采集MAC地址、唯一设备识别码（如IMEI、IDFA）、手机的基本配置信息（如CPU、内存、屏幕、手机型号、操作系统等）、网络信息（如WIFI及蜂窝网络信息、IP地址等）。

    <!-- ①、数美科技---隐私政策：https://www.fengkongcloud.com/legal/cn/privacy.html -->

    6、转让

    我们不会将您的个人信息转让给任何公司、组织或个人，但以下情况除外：

    1） 在获取您明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；

    2）
    随着我们业务的持续发展，我们有可能进行合并、收购、资产转让等交易，我们按照法律法规及不低于本协议所要求的标准继续保护或要求新的控制者继续保护您的个人信息。但如涉及到个人信息转让时，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，重新向您征求授权同意。

    7、披露

    7.1除非本协议另有约定或法律明确规定，我们不会公开披露您的个人信息。

    7.2根据相关法律法规及国家标准，以下情形中，我们可能会共享、转让、公开披露个人信息无需事先征得您的授权同意：

    1） 与国家安全、国防安全直接相关的；与公共安全、公共卫生、公共知情等重大公共利益直接相关的；

    2） 与犯罪侦查、起诉、审判和判决执行等直接相关的；

    3） 出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的；

    4） 所收集的个人信息是您自行向社会公众公开的；

    5） 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；

    6） 与我们履行法律法规规定的义务相关的；

    7.3请注意，您在使用掌动科技服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息。请您谨慎共享或公开，如所涉信息包含他人个人信息，请您在共享或公开前取得他人同意。

    7.4请知悉，根据法律规定，若我们采取技术措施和其他必要措施处理个人信息，使得数据接收方无法重新识别特定个人且不能复原，经上述处理后的数据的共享、转让、公开披露无需另行向您通知并征得您的同意。


    五、您的权利


    在您使用掌动科技游戏服务期间，我们可能会视游戏产品具体情况为您提供相应的操作设置，以便您可以访问、更正、删除您的相关个人信息，以及行使改变授权同意的范围与注销账号的权利，您可参考相应游戏产品的具体指引进行操作。此外，我们还设置了投诉举报渠道（电话：0571-56690777），您的意见我们将在15个工作日内予以反馈和处理。

    您的权利包括：

    8、您有权访问您的个人信息，您可以按照我们提供的游戏产品和服务的相关说明（或设置），对您已提供给我们的相关个人信息进行查阅。包括：

    1）账号信息：您可以通过相关游戏产品页面随时登陆您的个人中心，以访问您账号中的个人资料信息，包括：头像、昵称、性别、出生年月等；

    2）使用信息：您可以通过相关游戏产品页面随时查阅您的使用信息，包括：历史记录、订单信息、账单记录等；

    3）其他信息：如您在访问过程中遇到操作问题需获取其他前述无法获知的个人信息内容，您可通过本隐私政策提供的方式联系我们。

    9、您有权更正/修改或补充您的个人信息

    当您发现您提供给我们的个人信息存在登记错误、不完整或有更新的，您可在我们的游戏产品和/或服务中更正/修改或补充您的个人信息

    1）对于您的部分个人信息，我们在产品的相关功能页面为您提供了操作设置，您可以直接进行更正/修改或补充，例如：“头像/昵称/密码/密保问题及答案/密保手机/安全邮箱。

    2）对于您在行使上述权利过程中遇到的困难，或者其他可能目前无法向您提供在线自行更正/修改服务的，经过对您身份的验证，且更正/修改或补充不影响信息的客观性和准确性的情况下，您有权对错误或不完整的信息作出更正或修改，或在特定情况下，尤其是数据错误时，通过我们公布的反馈与报错等措施将您的更正/修改或补充申请提交给我们，要求我们更正或修改您的数据，但法律法规另有规定的除外。但出于安全性和身份识别的考虑，您可能无法修改注册时提交的某些初始注册信息，例如：姓名/身份证号码。

    10、您有权删除您的个人信息

    1）对于您的部分个人信息，您可以通过本隐私政策第八条“联系我们”客服向我们申请删除您提供信息。一旦您删除后，我们即会对此类信息进行删除或匿名化处理，除非法律法规另有规定。

    2）当发生以下情况时，您可以直接要求我们删除您的个人信息，但已做匿名化处理或法律法规另有规定的除外：

    （1）当我们处理个人信息的行为违反法律法规时；

    （2）当我们收集、使用您的个人信息，却未征得您的同意时；

    （3）当我们处理个人信息的行为违反了与您的约定时；

    （4）当您注销了掌动通行证账号时；

    （5）当我们终止服务及运营时。

    11、您有权改变您授权同意的范围

    如前文所述，我们提供的游戏产品和服务的部分功能需要获得您使用设备的相关权限（包括：存储、相机、麦克风等，具体以产品实际获取的功能为准）。您可以在授权后随时撤回（或停止）对该权限的继续授权。例如您可以通过iOS设备中的“设置-隐私-照片”来关闭您对手机相册的授权。您也可以通过注销账号的方式，永久撤回我们继续收集您个人信息的全部授权。

    请您理解，每个业务功能可能需要开启部分权限并收集必要的个人信息才能得以完成。当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理服务。

    12、您有权注销您的账号

    如果您需要注销您的账号，您可以通过“游戏→设置→账号注销”进入自助注销界面申请注销。

    如您是游戏的联运渠道用户,由于与您游戏相关的账户系由联运渠道方管理及运营，您可以按照联运渠道方提供的账号注销途径，申请注销账号。

    请您理解，当您注销账号后，我们将停止为您提供该产品及服务；且该账号产品及服务在使用期间已产生但未消耗完毕的权益及未来的逾期利益等全部权益将被清除；该账号下的内容、信息、数据、记录等将会被删除或匿名化处理（但法律法规另有规定或监管部门另有要求的除外，如依据《中华人民共和国网络安全法》规定，您的网络操作日志将至少保留六个月的时间）。账号完成注销后，将无法恢复。

    更多关于账号注销的流程、条件等事项详见《账号注销协议》。如您在谨慎考虑后仍执意决定注销您的账号的，您可以通过上述途径向我们提交注销申请，我们将在受理之日起15个工作日内完成处理。在您主动注销账号之后，我们将停止为您提供的产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。对于您在行使上述权利过程中遇到的困难与疑问，您可以通过客服电话（0571-56690777）咨询我们。

    在以下情形中，按照法律法规要求，我们将无法响应你的请求：

    1）与我们履行法律法规规定的义务相关的。

    2）与国家安全、国防安全直接相关的。

    3）与公共安全、公共卫生、重大公共利益直接相关的。

    4）与犯罪侦查、起诉、审判和判决执行等直接相关的。

    5）有充分证据表明您存在主观恶意或滥用权利的。

    6）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的。

    7）响应你的请求将导致你或其他个人、组织的合法权益受到严重损害的。

    8）涉及商业秘密的。


    六、协议变更


    我们可能会适时对本协议进行修订。当本协议的条款发生变更时，我们会在版本更新时以适当的方式向您提示变更后的协议。请您仔细阅读变更后的隐私政策内容,您继续使用掌动科技游戏表示您同意我们按照更新后的隐私政策收集、处理或使用您的个人信息。


    七、未成年人保护


    我们高度重视未成年人个人信息的保护问题，并持续探索更新的未成年人个人信息保护方式。

    我们会积极按照国家防沉迷政策要求，通过启用防沉迷系统保护未成年人的合法权益。我们会通过实名身份等信息校验判断相关账号的实名信息是否为未成年人，进而决定是否将此账号纳入到防沉迷体系中。另外，我们会收集您的登录时间、游戏时长等信息，通过从系统层面自动干预和限制未成年人游戏时间、启用强制下线功能等方式，引导未成年人合理游戏，并在疑似未成年人消费后尝试联系其监护人进行提醒、确认与处理，帮助未成年人健康上网。

    同时，为更好地保护未成年身心健康，促使未成年人健康上网，我们可能在国家有关网络游戏防沉迷政策规定的基础上，实施更为严格的防沉迷措施。同时我们也不断研究与测试各种保护未成年人的新技术。

    另外，我们还建立了家长监护工程。家长监护工程可以帮助家长或监护人了解孩子的游戏情况，设置消费限额以及每天的游戏时长和时段，协助未成年人的家长或监护人保护未成年人免受不良游戏习惯的伤害。您可以在我们的官网中了解有关掌动科技游戏防沉迷系统和家长监护工程的介绍。同时，我们也建立了未成年人主动服务平台，在疑似未成年人消费后尝试联系其监护人进行提醒、确认与处理，您可以通过掌动科技客户服务热线0571-56690777与我们联系。

    若您是未成年人的法定监护人，请您关注您所监护的未成年人是否是在取得您的授权同意之后使用掌动科技游戏的服务或提供其个人信息。如果您对您所监护的未成年人的个人信息有疑问，请通过第八条中的联系方式与我们联系。


    八、联系我们


    当您有其他的投诉、建议、未成年人个人信息相关问题时，请通过0571-56690777与我们联系。您也可以将您的问题寄到如下地址：

    杭州市滨江区滨盛路1777号萧宏大厦19楼（收）

    我们将尽快审核所涉问题，并在验证您的用户身份后的十五个工作日内予以回复。


    九、其他


    本隐私政策于2021年 07 月 14 日更新，公示期自2021年 07 月 14 日至2021年 07月 21 日 ，公示期结束后自2021年 07 月 22 日起生效。
  </div>
</template>

<script >
export default {
  data() {
    return {

    }
  }
  }
</script>

<style lang="scss" scoped>

</style>