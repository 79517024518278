import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/reset.css'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

import Vant from 'vant'
import 'vant/lib/index.css'

// 完整引入elementUI组件库
import ElementUI from 'element-ui'
// 这个按需引入的时候也需要导入样式才生效（虽然文档没引入）
import 'element-ui/lib/theme-chalk/index.css'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'amfe-flexible'

// 阿里图标库
import '@/assets/iconfont/iconfont.css'

Vue.use(Antd)
Vue.use(Vant)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.use(BootstrapVue)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
