<template>
  <div>
    杭州掌动科技股份有限公司（以下简称“掌动科技”）在此特别提醒用户（或简称“您”）认真阅读、充分理解本《掌动科技用户服务协议》(下称“本协议”)——您应认真阅读、充分理解本协议中各条款，特别涉及免除或者限制掌动科技责任的免责条款、对您权利限制条款、约定争议解决方式条款等。前述条款可能以黑体加粗、颜色标记或其他合理方式提示您注意，您对该等条款的确认将可能导致您在特定情况下的被动、不便、损失，您在确认同意本协议之前或在使用掌动科技提供的服务之前再次阅读前述条款。

    请您审慎阅读并选择“接受”或“不接受”本协议（未成年人应在法定监护人陪同下阅读）。除非您接受本协议所有条款，否则您无权使用掌动科技提供的相关服务。您使用掌动科技服务的行为将视为对本协议的接受，并同意接受本协议各项条款的约束。修改本协议条款时，掌动科技将于相关页面公告修改的事实，而不另行对用户进行个别通知。若用户不同意修改的内容，可停止使用全部掌动科技所提供的服务。若用户继续使用掌动科技所提供的任意服务，即视为您已接受掌动科技所修订的内容。

    如果您未满18周岁，需取得监护人的同意并在监护人的陪同下阅读本协议，并在取得监护人同意您享用掌动科技提供的服务、向掌动科技提供的服务支付相关费用的行为，以及监护人同意本协议全部条款之后，方可享用掌动科技提供的服务。您使用掌动科技服务的行为将视为您已经履行上述义务。

    本协议是用户与掌动科技之间关于您通过掌动通行证使用掌动科技提供的服务所订立的协议。本协议描述掌动科技与您之间关于就掌动科技提供的服务的权利义务。

    1、服务内容
    1.1、掌动科技服务的具体内容由掌动科技根据实际情况提供。掌动科技保留随时变更、中断或终止部分或全部服务的权利。

    1.2、掌动科技在提供服务时，可能会对部分服务（例如网络游戏及其他电信增值服务）的用户收取一定的费用。在此情况下，掌动科技会在相关页面上做出明确提示。如用户不同意支付该等费用，则可不接受相关服务。

    1.3、您理解：掌动科技仅提供相关服务，除此之外与相关服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由用户自行负担。

    1.4、您应使用正版软件接受服务。

    2、知识产权声明
    2.1、掌动科技所提供的服务的相关著作权、软件著作权、美术作品、专利权、商标权、商业秘密及其他一切合法权益，均属掌动科技所有。未经掌动科技书面同意，您不得以任何方式将掌动科技所提供的服务（包括但不限于游戏整体、游戏内所包含的所有美术、音乐、文字作品或其他游戏构成要素、组成部分，以及掌动科技运行呈现的连续动态画面）擅自下载、重制、复制、传输、改作、改编、编辑或进行商业性使用或通过信息网络传播掌动科技所提供的服务。掌动科技拥有向您提供服务过程中所产生并储存于掌动科技服务器中的任何数据信息（包括但不仅限于账号等数据信息）的所有权。

    2.2、除非本协议另有约定，否则，未经掌动科技事先书面同意，您不得实施下列行为（无论是营利的还是非营利的）：

    1）复制、改编、翻录、反编译（de-compile）、反汇编（disassemble）、传播掌动科技软件的程序、使用手册和其它图文音像资料的全部或部分内容，或对任何功能或程序进行反向工程（reverse
    engineering）；

    2）公开展示或以各种形式、在各种第三方软件、网站、平台（包括但不限于网络直播平台、网站、APP等）公开全部或部分展示、复制、传播、播放掌动科技服务的内容，否则掌动科技将有权根据您的违约或侵权情节，采取相应的处理措施，且掌动科技保留进一步追究您法律责任的权利；

    3）利用掌动科技游戏软件从事任何营利行为；

    4）修改或遮盖掌动科技游戏软件程序、图像、动画、包装和手册等内容上的服务名称、公司标志、商标（或）版权信息、其他知识产权声明等内容；

    5）其它违反商标法、著作权法、著作权法实施条例、计算机软件保护条例、侵权责任法和相关法律法规、国际条约的行为。

    本协议未明确授予您的权利均由掌动科技保留。

    3、您的基本义务
    3.1、您需自行配备注册和使用网络所需的各项计算机及网络设备，并自行负担上网所需的各项费用。

    3.2、掌动科技向您提供的服务本身属于商业行为，您应根据本协议或掌动科技所公布的各项相关管理规定支付相应费用。

    3.3、账号注册

    3.3.1、您充分理解并同意，掌动科技会按照国家相关要求将您的实名注册信息（包括相关实名认证及年龄情况等）运用于防沉迷系统之中，即掌动科技可能会根据您的实名注册信息判断您是否年满18周岁、您提交的实名身份信息是否规范或实名验证是否通过等，从而决定是否对您的游戏账号予以防沉迷限制。

    3.3.2、注册过程中，您应按相关网页上的要求输入正确的个人基本资料，包括真实姓名和有效的身份证、护照、军人证号码。您承诺进行实名注册时，应提供有关您本人真实、合法、准确、有效的身份信息及其他相关信息，且不得以他人身份资料进行实名注册。您同意掌动科技可将您提交的实名信息提交给合法留存您信息的国家有权机关、金融机构、支付机构和有关组织对您提交的实名信息进行核验。

    3.3.3、您输入的姓名和身份证、护照、军人证号码结合其与之对应的证件，作为您的唯一有效身份证明；在您无法提供有效证明其身份时，掌动科技有权拒绝提供任何信息或承担任何义务。

    3.3.4、如果您提供的资料不准确、不真实、不合法有效，用户需承担因此引起的相应后果，并且掌动科技保留结束您使用掌动科技各项服务的权利。

    3.3.5、在法律法规允许的范围内，用户同意掌动通行证账号所有权以及与注册、使用掌动通行证账号相关的服务数据和记录，包括但不限于所有注册、登录、消费记录和相关的使用统计数据，归掌动科技所有。发生争议时，用户同意以掌动科技的系统数据为准。

    3.3.6、若任何人因无法提供当前账号所对应的居民身份证信息证实其对账号拥有合法权利或授权，导致掌动科技无法为其提供或继续提供服务的，掌动科技将不承担因此产生的任何责任。用他人身份证号码及杜撰虚假身份证号码注册的用户的权益在游戏中将得不到任何保障。对提供虚假身份证号码的用户，掌动科技有权随时中止或终止向用户提供本协议项下的服务（包括收费服务）而无需对用户或任何第三方承担任何责任；对用他人身份证号码注册的用户，应该身份证号码对应之本人请求，掌动科技有权随时中止或终止向该身份证号码对应之账号提供本协议项下的服务（包括收费服务）而无需对用户或任何第三方承担任何责任。

    3.4、您有义务妥善保管及正确、安全地使用在注册服务时获得的账号及密码，并为此账号及密码登入系统后所产生的一连串行为或活动依法享有权利和承担责任。

    3.5、为维护自身权益，您不应将账号及密码泄漏或提供第三人知悉或出借或转让与他人使用，如因您自身过错或过失而导致账号或密码泄漏而给您自身造成损失，您应自行承担责任。掌动科技员工不会在电话或邮件中询问用户的密码，如果您遗失了密码，掌动科技针对处理此问题之服务保留索取额外费用的权利。

    3.6、若您发现账号或密码遭他人非法使用或有异常使用的情形，应立即通知掌动科技，并提交该账号为本人所有的有关证明，以便申请该账号的暂停使用，对账号暂停使用前您自身或任何第三方因此而遭受的损失，掌动科技不承担赔偿责任。若您没有提供您个人有效身份证件或者您提供的个人有效身份证件与所注册的身份信息不一致的，掌动科技有权拒绝您的上述请求，因此造成损失的，您应自行承担责任。

    3.7、您同意了本协议的条款之后，掌动科技将授予允许用户以一个合法账号而获得掌动科技提供的服务的权利。您不得将该账号以任何方式提供给他人使用，包括但不限于不得以转让、出租、借用等方式提供给他人作包括但不限于直播、录制、代打代练等商业性使用。否则，因此产生任何法律后果及责任均由您自行承担，且掌动科技有权对您的账号采取包括但不限于警告、限制或禁止使用账号全部或部分功能、删除账号及游戏数据及其他相关信息、封号直至注销的处理措施，因此造成的一切后果由您自行承担。

    3.8、您不可以使用任何第三方程序登录或使用掌动科技所提供的服务。您不可以使用掌动科技的任何知识产权，来创造或提供相同或类似的服务，如仿真服务器等。您不可以对掌动科技服务器采取任何的攻击行为，或是尝试着使掌动科技服务器过度负荷。您在拥有合法账号的情况下，可以在掌动科技的服务器上传使用服务所必须的资料，但资料的内容不得存在下列情形：

    1）侵犯任何第三者的知识产权、版权或公众/私人权利；

    2）违反任何法律、法规、规定或公序良俗；

    3）包含任何危害国家安全、毁谤他人、性骚扰、种族歧视或对孩童有不良影响的内容；

    4）包含病毒、木马、定时炸弹等可能对掌动科技系统造成伤害或影响其稳定性的内容。

    您理解并同意，若您违反以上任何规定，掌动科技有权依照上传内容的严重性采取任何动作包括但不限于终止用户账号的使用权等。您应自行承担自身损失（包括但不限于账户资料即虚拟财产（游戏币、游戏装备及其他游戏道具）过期、清空等）以及一切相应法律责任。您在此放弃并同意掌动科技执行任何有关您上传的内容的知识产权。

    4、用户信息保护
    4.1、根据协议条款的约定，掌动科技有权审查您注册所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户账号的安全、有效；用户有义务妥善保管其账号及密码，并正确、安全地使用其账号及密码。任何一方未尽上述义务导致账号密码遗失、账号被盗等情形而给您和他人的民事权利造成损害的，应当承担由此产生的法律责任。

    4.2、您对登录后所持账号产生的行为依法享有权利和承担责任。如若有任何第三方向掌动科技发出指示，在确认其提供账户、密码信息准确的情况下，用户同意且掌动科技有权视为该行为获得了用户的充分授权，该行为所产生结果直接归属于用户本身。

    4.3、用户发现其账号或密码被他人非法使用或有使用异常的情况的，应及时根据掌动科技公布的处理方式通知掌动科技，并有权通知掌动科技采取措施暂停该账号的登录和使用。

    4.4、掌动科技根据用户的通知采取措施暂停用户账号的登录和使用的，掌动科技应当要求用户提供并核实与其注册身份信息相一致的个人有效身份信息。

    4.4.1、掌动科技核实用户所提供的个人有效身份信息与所注册的身份信息相一致的，应当及时采取措施暂停用户账号的登录和使用。

    4.4.2、用户没有提供其个人有效身份证件或者用户提供的个人有效身份证件与所注册的身份信息不一致的，掌动科技有权拒绝用户上述请求。

    4.5、用户为了维护其合法权益，向掌动科技提供与所注册的身份信息相一致的个人有效身份信息时，掌动科技应当为用户提供账号注册人证明、原始注册信息等必要的协助和支持，并根据需要向有关行政机关和司法机关提供相关证据信息资料。

    4.6、掌动科技要求用户提供与其个人身份有关的信息资料时，应当事先以明确而易见的方式向用户公开其隐私权保护政策，并采取必要措施保护用户的个人信息资料的安全。未经用户许可掌动科技不得向任何第三方提供、公开或共享用户注册资料中的姓名、个人有效身份证件号码、联系方式、家庭住址等个人身份信息，但下列情况除外：

    1）用户或用户监护人授权掌动科技披露的；

    2）有关法律要求掌动科技披露的；

    3）司法机关或行政机关基于法定程序要求掌动科技提供的；

    4）掌动科技为了维护自己合法权益而向用户提起诉讼或者仲裁时；

    5）应用户监护人的合法要求而提供用户个人身份信息时。

    4.7、您理解并同意：掌动科技及其关联公司或其合作的第三方可以根据您的用户信息，通过短信、电话、邮件等各种方式向您提供关于掌动游戏的活动信息、推广信息等各类信息或进行用户调研等回访服务。如您不愿意收到类似短信、电话、邮件，可直接告知客服人员或点击短信、邮件中的退订提示或链接，完成退订手续。

    4.8、您理解并同意：为了更好地向您提供游戏服务，改善游戏体验，对于用户注册资料中除4.6条所述之外的其他信息，您同意掌动科技及其关联企业或合作对象，可以在合理范围内搜集、分析、处理、保存、传递及使用，以提供用户其它信息及服务或做成会计资料，或进行网络行为的调查或研究，或其它任何合法使用。

    4.9、当用户要求掌动科技给予任何技术上的协助时，用户授权掌动科技远程检视与更改用户计算机的内容。为了更新程序的需要，用户授权掌动科技

    1）从用户的计算机上传档案/文件/程序；

    2）下载档案/文件/程序到用户的计算机。

    4.10、若掌动科技发现疑似遭盗用的会员账号，有权立即终止该会员账号的使用权。

    4.11、若因为用户自身原因，而导致账号、密码遭他人非法使用时，掌动科技将不负责处理。用户自身原因包括但不限于：任意向第三者透露账号和密码及所有注册资料；多人共享同一个账号；安装非法或来路不明的程序等。

    4.12、保护用户信息及隐私是掌动科技一项基本原则，除本协议约定外，掌动科技对用户信息收集、使用及保护等遵循《隐私政策》等协议规定。您知悉并同意，如国家有权机关依法要求掌动科技协助调查您游戏账号（包括实名身份信息）及游戏相关数据（如充值、消费、交易等数据）等信息的，掌动科技可将前述相关信息提供给国家有权机关。

    5、用户使用服务的限制
    5.1、您在使用掌动科技服务时须遵守法律法规，应当自行承担您所发布的信息内容所涉及的责任。包括但不限于不得发布下列内容：

    1）反对中华人民共和国宪法所确定的基本原则的；

    2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；

    3）损害国家荣誉和利益的；

    4）煽动民族仇恨、民族歧视，破坏民族团结的；

    5）破坏国家宗教政策，宣扬邪教和封建迷信的；

    6）散布谣言，扰乱社会秩序，破坏社会稳定的；

    7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；

    8）侮辱或者诽谤他人，侵害他人合法权益的；

    9）宣扬、教唆使用外挂、私服以及木马的相关内容的；

    10）发布任何经掌动科技合理判断为不妥当或者掌动科技未认可的软件、文件等在内的主页地址或者链接的；

    11）含有中华人民共和国法律、行政法规禁止的其他内容的。

    5.2、除非法律允许或掌动科技书面许可，您不得从事下列行为：

    1）删除游戏软件及其副本上关于著作权的信息；

    2）对游戏软件进行反向工程、反向汇编、反向编译或者以其他方式尝试发现软件的源代码；

    3）对游戏软件进行扫描、探查、测试，以检测、发现、查找其中可能存在的BUG或弱点；

    4）对游戏软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经合法授权的第三方工具/服务接入软件和相关系统；

    5）修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论上述行为是否为商业目的；

    6）通过非掌动科技开发、授权的第三方软件、插件、外挂、系统，使用掌动网各游戏及掌动科技服务，或制作、发布、传播非掌动科技开发、授权的第三方软件、插件、外挂、系统；

    7）对游戏中掌动科技拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；

    8）建立有关掌动科技的镜像站点，或者进行网页（络）快照，或者利用架设服务器等方式，为他人提供与掌动科技服务完全相同或者类似的服务；

    9）将掌动科技的服务或产品任意部分分离出来单独使用，或者进行其他的不符合本协议的使用；

    10）使用、修改或遮盖掌动科技的名称、商标或其它知识产权；

    11）其他未经掌动科技明示授权的行为。

    5.3、掌动科技严禁您利用掌动科技所提供的服务而做与使用该等服务无关的行为，包括但不限于妨害他人名誉或隐私权；或使用自己、匿名或冒用他人或掌动科技名义散播诽谤、不实、威胁、不雅、猥亵、不法、攻击性或侵害他人权利的消息或文字，传播色情或其它违反社会公德的言论；传输或散布计算机病毒；从事广告或贩卖商品；从事不法交易或张贴虚假不实或引人犯罪的信息；或任何违反中华人民共和国法律法规的行为，亦不得利用使用该等服务的机会，与其他用户或他人进行非法的交流或对话。

    5.4、您对掌动科技服务管理人员所进行的询问应据实澄清，否则掌动科技有权随时终止用户使用服务；掌动科技发现不法或可疑的行为者，掌动科技有权随时通报司法或相关机关处理，并有权同时向该机关呈报用户的注册资料及行为供该等机关实施调查。您就因此所致生的不便或困扰，不得向掌动科技请求任何损害或补偿。

    5.5、您应就您在使用掌动科技所提供的服务过程中的行为或活动自行承担全部责任，掌动科技仅提供服务予您自行执行或与其他用户依照服务规则共同使用，掌动科技就您在使用掌动科技所提供的服务过程中的行为、活动或交易不负任何责任。

    5.6、下列行为将视为您违反本协议之行为：

    1）您所提供的个人资料不真实；

    2）您使用任何第三方程序登录或使用服务；

    3）您滥用所享受的权利或通过不正当手段使用掌动科技的服务；

    4）您有损害其他用户合法权益的行为；

    5）您发布违法信息、严重违背社会公德、以及其它违反法律禁止性规定的行为；

    6）您违反了本协议的其他任何规定；

    7）您在掌动科技游戏中使用、上传违法或不当词语、字符及其他内容的行为，包括用于角色命名；

    8）您在使用各种私服、外挂行为；盗取他人游戏账号、游戏物品；

    9）您在进行游戏账号交易、账号共享等影响账号安全的行为；未经掌动科技许可，擅自与其他用户进行游戏虚拟货币、游戏装备、游戏币及其他游戏道具等交易，或从第三方通过购买、接受赠与或者其他方式获得游戏虚拟货币、游戏装备、游戏币及其他游戏道具或游戏服务；

    10）您在未经掌动科技授权或认可的折扣渠道进行游戏充值、进行折扣游戏道具交易或委托未获掌动科技授权或认可的第三方代为充值，或者使用未经掌动科技授权或认可的第三方软件充值。

    对于您的上述违约行为，掌动科技有权立即中止或终止对用户提供部分或全部服务，并保留追究用户法律责任的权利。

    5.7、掌动科技在采取中止措施时，将告知用户中止期间，中止期间时长应该是合理的，中止期间届满后，掌动科技应当及时恢复对用户的服务。但中止期间届满时，如果用户的违约状态仍然存在的，则掌动科技有权延长中止服务的期间。

    5.8、掌动科技根据本条约定中止或终止对用户提供部分或全部服务的，掌动科技负举证责任。

    5.9、掌动科技发行的虚拟货币的使用范围仅限于兑换其提供的网络游戏产品和服务，不可用于支付，购买实物或者兑换其他单位的产品和服务。

    5.10、您充分理解并同意，为高效利用服务器资源，如果您360天没有使用掌动科技所提供的服务，掌动科技有权在提前通知的情况下，对该账号及账号下的游戏数据及信息采取处置措施，具体为：自第360天当天的24时起，掌动科技有权立即终止对用户提供部分或全部服务。收回该用户账号，删除包括但不限于注册信息、等级信息、角色信息、物品信息等一切与注册账号相关的信息，且勿需对删除该账号所带来的任何利益损失负责。

    6、服务的终止、中断和变更
    6.1、发生下列情形之一时，掌动科技有权停止或中断向用户提供服务：

    1）对于网络设备进行必要的保养及施工；

    2）发生突发性的网络设备故障时；

    3）掌动科技所用的网络通信设备由于不明原因停止，无法提供服务时；

    4）不可抗力因素致使掌动科技无法提供线上服务；

    5）相关政府机构的要求；

    6）利用程序的漏洞和错误（Bug）破坏游戏的正常进行或传播该漏洞或错误（Bug），掌动科技有权冻结或者封停该用户账号，对于情节特别严重者可提起法律诉讼。

    6.2、掌动科技有权仅根据其判断，单方决定新增、修改、删除、暂停或终止其所提供的全部或部分服务（包括且不限于增加、暂停或终止某个游戏的运营），且无需另行个别通知用户，用户不得因此要求任何补偿或赔偿。不管由于何种原因终止游戏服务，用户应采取相应的措施自行处理游戏虚拟物品，包括但不限于注销或停止使用用户账号、游戏虚拟物品等相关事宜。用户不得因全面终止游戏服务而要求掌动科技承担除用户已经购买但尚未使用的游戏虚拟货币外任何形式的赔偿或补偿责任，包括但不限于因不再能继续使用用户账号、游戏虚拟物品等而要求的赔偿。

    6.3、在本协议约定的情形下，掌动科技就停止、中断或变更向用户所提供的服务而可能产生的不便或损害，掌动科技对用户本人或任何第三人均不负任何损害赔偿责任。

    6.4、用户应了解并同意，掌动科技所提供的服务可能因公司本身、其它合作厂商或相关电信网络系统软硬件设备的故障、失灵，或因合作方及相关电信工作人员人为操作的疏失而全部或一部分中断、暂时无法使用，迟延或因他人侵入掌动科技系统篡改或伪造变造资料等，造成服务的停止或中断或用户档案缺失，用户不得要求掌动科技就此提供任何的补偿或赔偿。

    6.5、用户应确实遵守本协议及有关法律法规的规定。掌动科技对于用户是否违反本协议有最终决定权。若掌动科技经相关数据分析认定用户违反本协议，用户同意掌动科技可以随时终止用户的账号及密码使用权。

    6.6、为避免争议，本协议涉及到的有关技术方面的问题，用户同意以掌动科技系统所记载的数据作为判断标准，且该数据是唯一的、排他的结果。掌动科技保证该数据的真实性且该数据未经政府有关部门要求，不向包括用户在内的任何人披露，以保护掌动科技的商业安全。

    7、用户充值协议
    7.1、您选择充值服务，即表示用户完全接受本协议项下的全部条款。并了解掌动币的使用范围仅限于兑换自身提供的网络游戏产品和服务，不得用于支付、购买实物或者兑换其它单位的产品和服务。

    7.2、您充分理解并同意：游戏装备、游戏币及其他游戏道具等是掌动科技服务的一部分，掌动科技在此许可您依本协议而获得其使用权。

    7.3、关于用户充值的特别提示：

    7.3.1、在掌动科技游戏中，用户通过任何支付手段（包括但不限于银行卡充值、网吧充值、一卡通充值、手机卡充值、支付宝充值、微信充值等）充值到掌动通行证账号所直接获得的均为"掌动币"，充值到游戏的直接获得为"游戏币"。

    7.3.2、掌动币可用于兑换掌动科技旗下相应游戏产品的游戏币或相应服务。

    7.3.3、游戏币可以用于购买掌动科技游戏中的指定的装备、道具、商品、材料及指定可以购买的一系列物品。

    7.4、用户充值一旦成功，充值即确定完成，掌动科技将不提供任何更改、修正、退费服务。正常情况下，用户不得向掌动科技要求退还充值金额，除非所充入金币本身无法使用到任何应用内容中，若发生异议用户可通过客服专线查询处理。

    7.5、在使用掌动科技充值系统时，用户必须仔细确认自己的账号，若因为用户自身输入账号错误、操作不当或不了解充值计费方式等因素造成充错账号、错选充值种类等情形而损害自身权益，不得因此要求掌动科技退费或作任何补偿或赔偿。

    7.6、若因用户违反本协议内容，掌动科技因此冻结账号及终止账号使用资格者，用户不得因此要求掌动科技退费或作任何补偿或赔偿。

    7.7、若因掌动科技充值系统自身故障原因造成用户充值失实，在掌动科技恢复、存有有效数据和用户提供合法有效凭证的情况下，掌动科技将根据用户充值情况作出变动措施。

    7.8、因用户选错金额，错误充值（即充值的金额小于充值卡的金额）被掌动科技合作第三方充值渠道商全额扣除，造成系统充值额小于用户实际充值额的。不得因此要求掌动科技作任何补偿或赔偿。

    7.9、因充值系统自身故障原因，造成系统充值额大于用户实际充值额的，掌动科技有权追回差额。

    7.10、掌动科技只提供180天内的掌动通行证账号下掌动币（或游戏币）充值及消耗记录查询。

    7.11、您知悉并同意，您使用法定货币进行游戏充值购买或兑换游戏虚拟货币、虚拟道具及其它虚拟物品或服务的，视为您购买了游戏服务，您所购买或兑换的游戏虚拟货币、虚拟道具及其它虚拟物品或服务将不能退还或兑换成法定货币，但法律另有强制性规定的除外。

    8、风险承担
    您同使用掌动科技所提供的服务是出于您个人意愿，并愿自负任何风险，包括但不限于您因使用掌动科技服务或自行由掌动科技公司官方网站或掌动科技相关游戏官方网站下载游戏或资料图片而导致用户或您所使用的计算机软、硬件的损害，或发生任何资料的流失等。

    9、对于用户的惩罚类型及定义
    若您违反本协议的规定，除了根据其他条款规定受到相应处罚外，还可能会遭受以下一项或几项惩罚：

    1）警告：警告仅仅是针对违反游戏规则的玩家做出的教育导向，它是用于正常管理游戏运行的一种方式；

    2）禁言：关闭违规玩家的部分或全部聊天频道，强制暂停玩家用户账号的线上对话功能，使玩家用户账号无法与其他玩家对话，直到此次处罚到期或是取消；

    3）暂时冻结玩法：将违规玩家的游戏角色进行限制，限制其局部游戏操作，直到此次处罚到期或是取消；

    4）永久冻结玩法：将违规玩家的游戏角色进行限制，限制其局部游戏操作，且永久不予以解除或释放；

    5）暂时禁止登录：违规玩家的用户账号将在一定的时间内暂时无法登录使用掌动科技提供的某个或者某几个游戏，直到此次处罚到期或是取消；

    6）永久禁止登录：违规玩家的用户账号将永久无法登录使用掌动科技游戏服务；

    7）强制离线：强制让违规玩家离开当前游戏，结束玩家当前游戏程序的执行；

    8）封停账号：暂停或永久停止违规玩家游戏账号登录掌动科技游戏服务的权利；

    9）删除档案：将违规玩家在游戏世界中的人物档案删除，不让该人物再出现在游戏世界；

    10）承担法律责任：玩家的不当行为对他人或者掌动科技造成损害或者与现行法律规定相违背的，违规玩家要依法承担相应的民事、行政或刑事责任；

    11）解散组织：解散玩家成立的包括但不限于帮派、工会、势力等组织；

    12）修改昵称：对于玩家包括但不限于人物昵称、帮派名称、摊位名称、商店名称、宠物名称、召唤兽昵称、空间名称等可以由玩家自主命名的昵称或名称进行强制修改；

    13）暂时限制游戏行为：违规玩家的用户账号将在一定的时间内被限制使用部分游戏服务；

    14）撤销交易：撤销违规交易、将交易双方付出的游戏虚拟物品予以还原；

    15）扣除违规交易所得：扣除违规交易中，违规一方或违规双方所获得的游戏虚拟物品；

    16）交易功能限制：暂时冻结违规交易中，违规一方或违规双方所获得的游戏虚拟物品，并持续考察其在指定期限内的游戏内行为。如指定期限内其无再次违规的，到期自动解冻相应的游戏虚拟物品；如指定期限内其再次违规的，在掌动科技认定其违规行为存在时扣除其被冻结的游戏虚拟物品；

    17）冻结游戏虚拟物品：对违规玩家限定其游戏角色的游戏虚拟物品的相关功能，包括但不限于物品的使用、交易、给予等；

    18）收回游戏虚拟物品：对于玩家因欺诈或其他违规行为而获取的游戏虚拟物品，包括但不限于游戏虚拟物品进行收回；

    19）其他处罚措施：包括但不限于没收不正当利益（如玩家通过不正当途径获得的游戏虚拟物品）、倒扣数值（包括但不限于经验值等）、删除论坛帖子、屏蔽论坛账号、暂时中断服务等。

    10、损害赔偿
    用户若违反协议或相关法律法规规定，导致掌动科技或其关系企业、受雇人、受托人、代理人及其他相关履行辅助人因此而受到损害或支出费用（包括但不限于由法律诉讼、行政程序等所支出的诉讼费用、律师费用、实际遭受损失的费用等），用户应负担损害赔偿责任。

    11、免责及赔偿限制
    11.1、掌动科技就其所提供的服务，不负任何明示或默示的担保责任，而其所提供的服务的稳定、安全、无误及不中断性亦有可能因本协议第6条所述情形而受到影响。用户应自行承担使用执行服务所有的风险及因此可能导致的损害，包括而不限于其因执行线上游戏或自行由掌动科技公司官方网站或掌动科技相关游戏官方网站下载游戏或资料图片而导致用户或其所使用的计算机系统非因掌动科技主观过错造成的损害，或发生任何资料的流失等。用户不得以掌动科技的游戏服务产生的任何稳定性问题提出退款要求及各类损害赔偿。

    11.2、掌动科技对其服务不保证不出现任何程序BUG，并对由此可能产生的问题不承担任何赔偿责任。

    11.3、掌动科技在其公司网站的所有网页上所提供的所有链接，可能链接到其他个人、公司或组织的网站，提供该等网站的目的，是便利用户自行搜寻或取得信息，掌动科技对于被链接的个人、公司或组织的网站所提供的产品、服务或信息，不担保其真实性、完整性、实时性或可信度，除非有特别说明，这些个人、公司或组织与掌动科技间亦不存在任何雇用、委任、代理、合伙或其它类似的关系。掌动科技在游戏官方网站、游戏官方论坛、游戏内可能刊登商业广告或其他推广活动信息。这些广告或推广活动信息是由广告商或商业服务提供者提供，并承担相应责任，掌动科技仅提供刊登内容的媒介。用户通过该等链接的网站或广告所购买的商品或服务，其交易行为仅存在于用户与该商品或服务的提供者之间，与掌动科技无关，掌动科技不就用户与该商品或服务的提供者之间所产生的交易行为承担任何法律责任，用户应予以特别留意。

    11.4、掌动科技对于用户使用其服务或无法使用网络所导致的任何直接、间接、衍生的损害或所失利益不负任何损害赔偿责任。若依法无法完全排除损害赔偿责任时，掌动科技的赔偿责任亦以用户就使用服务所支付掌动科技的价款为限。

    11.5、掌动科技服务为互联网服务，可能会受到各个环节不稳定因素的影响，存在因不可抗力、计算机病毒、黑客攻击、系统不稳定、用户所在位置、用户关机，非法内容信息、骚扰信息屏蔽以及其他任何网络、技术、通信线路、信息安全管理措施等原因造成的服务中断、受阻等不能满足用户要求的风险，用户须明白并自行承担以上风险。

    11.6、如您在游戏内的违规行为给掌动科技或第三方造成损害的，您应承担相应的法律责任，而且针对自身的损害掌动科技有权采取一切合理的行动，包括但不限于收回游戏虚拟物品等，掌动科技并有权向您索回损失额并要求您承担掌动科技合理的追索费用。

    12、广告信息和促销计划
    12.1、掌动科技在提供服务过程中可能在有关网站位置上刊登商业广告、或其它活动促销的广告。这些内容系广告商或商品服务提供者所为，掌动科技仅是刊登内容的媒介。用户通过掌动科技官方网站或其所链接的网站所购买的服务或商品，其交易行为仅存于用户与该商品或服务的提供者之间，与掌动科技无关。

    12.2、掌动科技在提供服务过程可能以电子邮件方式不时向用户发送掌动科技的游戏电子期刊或相关市场活动信息，如用户不愿意收到类似电子邮件，可点击邮件中的退订链接，完成退订手续。

    13、修改协议
    由于行业政策及法律法规的不断变化和发展，掌动科技保留随时修改本协议的权利，修改本协议时，掌动科技将于相关页面公告修改的事实，而不另行对用户进行个别通知。若用户不同意修改的内容，可停止使用全部掌动科技所提供的服务。若用户继续使用掌动科技所提供的任意服务，即视为用户业已接受掌动科技所修订的内容。

    14、适用法律及争议解决
    14.1、本协议签订地为中华人民共和国浙江省杭州市滨江区。

    14.2、本协议的订立、执行和解释及争议的解决均应适用中华人民共和国大陆地区法律（不包含冲突法）。

    14.3、本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。

    14.4、如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。

    14.5、本协议中的标题仅为方便而设，不具法律或契约效果。

    14.6、如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向本协议签订地有管辖的人民法院提起诉讼。

    14.7、在适用法律允许的最大范围内，掌动科技保留对本协议的最终解释权。用户如对本协议有任何疑问，请登录掌动科技或官方网站获取相关信息或拨打本公司客服电话。

    重要提示
    青少年用户必须遵守全国青少年网络文明公约：要善于网上学习，不侮辱欺诈他人；要增强自护意识，维护网络安全，要有益身心健康，不沉溺虚拟时空。
  </div>
</template>

<script >
export default {
  data() {
    return {

    }
  }
  }
</script>

<style lang="scss" scoped>

</style>