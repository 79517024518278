import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { title: '首页' }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },

  {
    path: '/game',
    name: 'Game',
    component: () => import('../views/Game.vue'),
    meta: { title: '旗下游戏' }
  },

  {
    path: '/join',
    name: 'Join',
    component: () => import('../views/Join.vue'),
    meta: { title: '加入我们' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta: { title: '联系我们' }
  }
  // {
  //   path: '/Product',
  //   name: 'Product',
  //   component: () => import('../views/Product.vue')
  // }
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  // mode: 'history',// 将这里的'mode'属性从'hash'改为'history'
  routes
})

// router.afterEach((to) => {
//   document.title = `${to.meta.title || ''}-杭州掌动科技股份有限公司`
// })
document.title = '杭州掌动科技股份有限公司'
// 需要添加内容
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}



export default router
